<template>
  <div class="details-header">
    <div class="nav">
      <div
        class="nav-item"
        :class="navIndex === item.id ? 'active' : ''"
        v-for="item in nav"
        :key="item.id"
        @click="toPushNav(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="login">
      <div class="user">
        <i
          style="width: 24px; height: 24px"
          v-if="!userInfo.phone || !userInfo.headImg"
          class="icon icon-user"
        ></i>
        <img
          v-else
          :src="los + userInfo.headImg + '?' + Date.now()"
          alt=""
          class="tx-img"
          @click="toPushUser(info[0])"
        />
        <span
          v-if="userInfo.phone"
          @click="toPushUser(info[0])"
          style="cursor: pointer; color: #fff"
          >{{ userInfo.phone | phoneFilter }}</span
        >
      </div>
      <div
        class="login-btn"
        :style="{ color: userInfo.phone ? '#bbb' : '#fff' }"
        @click="loginORquit"
      >
        {{ userInfo.phone ? '退出' : '登录' }}
      </div>
      <!-- <div class="userinfo">
        <div
          class="info-item"
          v-for="item in info"
          :key="item.id"
          @click="toPush(item)"
        >
          {{ item.text }}
        </div>
      </div> -->
    </div>
    <!-- 登录弹窗 -->

    <el-dialog :visible.sync="dialogVisible" width="400px">
      <div class="body">是否确认退出登录</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toLogout">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>
import { logout } from 'services/login';
const los = window.los
export default {
  name: 'DetailsHeader',
  components: {
  },
  data () {
    return {
      los,
      dialogVisible: false,
      navIndex: 2,
      info: [
        { id: 1, text: '个人中心', url: '/userHome', },
        { id: 2, text: '关注房源', url: '', },
        { id: 3, text: '看房记录', url: '', },
      ],
      nav: [
        { id: 1, url: '', name: '写字楼' },
        { id: 2, url: '/plant', name: '厂房' },
        // { id: 3, url: '', name: '仓库' },
        // { id: 4, url: '', name: '商铺' },
        // { id: 5, url: '', name: '土地' },
        // { id: 6, url: '/my', name: '我的' },
      ],
      userInfo: {}
    }
  },
  computed: {
  },
  mounted () {
    // 获取用户信息
    if (localStorage.userInfo) this.userInfo = JSON.parse(localStorage.userInfo)
    this.$bus.$on('getUserInfo', userInfo => {

      this.userInfo = userInfo
    })
  },
  methods: {
    // 退出-登录
    loginORquit () {
      if (this.userInfo.phone) {
        this.dialogVisible = true
      } else {
        setTimeout(() => {
          window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
        }, 500)
      }
    },
    toLogout () {
      logout().then(res => {
        if (res.data.status === 200) {
          this.userInfo = {}
          localStorage.removeItem('userInfo')
          localStorage.removeItem('token')
          this.dialogVisible = false
          return this.$message({ message: res.data.msg, type: 'success' });
        } else {
          return this.$message.error('退出登录失败，请再次点击')
        }
      })
    },
    toPushNav (item) {
      if (item.id === 2) {
        this.$router.push({
          path: item.url
        })
      }

      if (item.id === 1) {
        window.open(`${window.officeUrl}office`, '_blank');
      }
    },
    toPush (item) {
      this.$router.push({
        path: item.url
      })
    },
    toPushUser (item) {
      let routeUrl = this.$router.resolve({
        path: item.url
      })
      window.open(routeUrl.href, '_blank');
    },
  }
};
  </script>
  
  <style lang="less" scoped>
.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  opacity: 1;
  background: rgba(51, 48, 46, 1);
  padding: 0 calc(50% - 600px);
  .nav {
    display: flex;
    align-items: center;
    &-item {
      margin-right: 20px;
      font-size: 14px;
      line-height: 40px;
      color: rgba(187, 187, 187, 1);
      cursor: pointer;
      &.active {
        color: #fff;
      }
    }
  }
  .login {
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    .user {
      margin-right: 8px;
      display: flex;
      align-items: center;
      .tx-img {
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 14px;
      }
    }
    .icon {
      margin-right: 8px;
    }
    &-btn {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }
    &:hover {
      .userinfo {
        display: block;
      }
    }
    .userinfo {
      position: absolute;
      z-index: 100;
      top: 20px;
      background-color: #fff;
      display: none;
      border-radius: 4px;
      .info-item {
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    display: flex;
    align-items: center;
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
</style>
  