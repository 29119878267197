<template>
  <div class="plant-details">
    <div class="header" :style="{ top: headerTop + 'px' }" @click="back">
      <img src="../../../assets/back1.png" alt="" />
    </div>
    <DetailsCarousel
      v-if="Object.keys(details).length !== 0"
      :details="details"
      @back="back"
    />
    <div
      class="main"
      :style="{
        top: mainTop + 'px',
        borderRadius: mainStartTop === 44 ? '0' : '16px 16px 0px 0px',
        overflow: mainStartTop === 44 ? 'auto' : 'hidden'
      }"
      ref="main"
    >
      <DetailsHeaderInfo
        v-if="Object.keys(details).length !== 0"
        :details="details"
      />
      <div class="tab" v-if="tabVisible">
        <div
          class="tab-item"
          :class="item.id === tabIndex ? 'active' : ''"
          v-for="item in tab"
          :key="item.id"
          @click="scrollTab(item)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="main-title" id="id1">房源信息</div>
      <div class="house-info" v-if="Object.keys(details).length !== 0">
        <div class="title">详情介绍</div>
        <div
          class="content"
          v-html="
            details.details.replace(/\n|\r\n/g, '<br>').replace(/ /g, '  ')
          "
        ></div>
        <div class="title">基本信息</div>
        <div class="info-detail">
          <div class="info-detail-item">
            <label for="">房源编号</label>
            <div class="p">{{ details.buildingNo || '-' }}</div>
          </div>
          <div class="info-detail-item">
            <label for="">新旧程度</label>
            <div class="p">{{ details.yearType || '-' }}</div>
          </div>
          <div class="info-detail-item">
            <label for="">区域</label>
            <div class="p">{{ details.district }} - {{ details.street }}</div>
          </div>
          <div class="info-detail-item">
            <label for="">出租方式</label>
            <div class="p">
              {{ details.sublet ? '分租' : '整租' }}（{{ details.minArea }}㎡
              起租）
            </div>
          </div>
          <div class="info-detail-item">
            <label for="">起租时间</label>
            <div class="p">
              <span v-if="details.minLeaseMonth">{{
                details.minLeaseMonth | minLeaseMonthFilter
              }}</span>
              <span v-else>面议</span>
            </div>
          </div>
          <div class="info-detail-item">
            <label for="">租房押金</label>
            <div class="p">{{ details.cashPledge || '-' }}</div>
          </div>
          <div class="info-detail-item">
            <label for="">可租时间</label>
            <div class="p">
              {{ details.canLeaseTime | canLeaseTimeFilter }}
            </div>
          </div>
          <div class="info-detail-item">
            <label for="">楼层</label>
            <div class="p">
              {{ details.floorTypeName }}
            </div>
          </div>
          <!-- <div class="info-detail-item">
              <label for="">行业限制</label>
              <div class="p">无</div>
            </div> -->
          <div class="info-detail-item">
            <label for="">看房时间</label>
            <div class="p">
              {{
                details.lookTime === 1
                  ? '随时可看'
                  : details.lookTime === 2
                  ? '工作日可看'
                  : '周末节假日可看'
              }}
            </div>
          </div>
          <div class="info-detail-item">
            <label for="">可办环评</label>
            <div class="p">{{ details.canEia ? '是' : '否' }}</div>
          </div>
        </div>
        <div class="title">建筑详情</div>
        <div class="info-detail">
          <div class="info-detail-item">
            <label for="">建筑楼层</label>
            <div class="p">{{ details.floorTypeName || '-' }}</div>
          </div>
          <div class="info-detail-item">
            <label for="">建筑层高</label>
            {{ details.floorHeight ? details.floorHeight + '米' : '-' }}
          </div>
          <div class="info-detail-item">
            <label for="">建筑年份</label>
            <div class="p">{{ details.buildYear + '年' || '-' }}</div>
          </div>
          <div class="info-detail-item">
            <label for="">建筑结构</label>
            <div class="p">{{ details.structureTypeName || '-' }}</div>
          </div>
          <div class="info-detail-item">
            <label for="">楼板承重</label>
            <div class="p">{{ details.bearing + '吨' || '-' }}</div>
          </div>
        </div>
        <div class="title">价格详情</div>
        <div class="info-detail">
          <div class="info-detail-item">
            <label for="">水费</label>
            <div class="p" v-if="details.waterRate">
              {{ details.waterRate }}元/吨
            </div>
            <div class="p" v-else>面议</div>
          </div>
          <div class="info-detail-item">
            <label for="">电费</label>
            <div class="p" v-if="details.electricRate">
              {{ details.electricRate }}元/度
            </div>
            <div class="p" v-else>面议</div>
          </div>
          <div class="info-detail-item">
            <label for="">空调费</label>
            <div class="p" v-if="details.airConditioningFee">
              {{ details.airConditioningFee }}元/月
            </div>
            <div class="p" v-else>面议</div>
          </div>
          <div class="info-detail-item">
            <label for="">物业费</label>
            <div class="p" v-if="details.propertyCost">
              {{ details.propertyCost }}元/月
            </div>
            <div class="p" v-else>面议</div>
          </div>
          <div class="info-detail-item">
            <label for="">停车费</label>
            <div class="p" v-if="details.parkingFee">
              {{ details.parkingFee }}元/月
            </div>
            <div class="p" v-else>面议</div>
          </div>
          <div class="info-detail-item">
            <label for="">其它费用</label>
            <div class="p">{{ details.otherFee }}</div>
          </div>
        </div>
        <div class="title">房源特色</div>
        <div class="feature">
          <div
            class="feature-item"
            v-for="(item, index) in details.labels"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div class="main-title" id="id2">配套设施</div>
      <div class="house-info" v-if="Object.keys(details).length !== 0">
        <div class="title">基础设施</div>
        <ul class="facility">
          <li
            class="facility-item"
            v-for="item in details.facilitiesList"
            :key="item.id"
          >
            <img
              :src="los + item.icon + '?' + Date.now()"
              alt=""
              style="width: 18px; height: 18px"
              class="icon"
              :class="item.enable ? `` : `active`"
            />
            <div class="text" :class="item.enable ? 'state' : ''">
              {{ item.name }}
            </div>
          </li>
        </ul>
      </div>
      <!--周边-->
      <div class="main-title" id="id2">周边位置</div>
      <div
        id="mapBox"
        class="house-info"
        style="padding: 12px"
        v-if="Object.keys(details).length !== 0"
      >
        <div class="map">
          <b-map
            :adress="adress"
            :points="points"
            ref="map"
            @getMapSearchList="getMapSearchList"
          />
        </div>
        <div class="noun">
          <div class="noun-search">
            <div
              class="noun-search-item"
              :class="mapSearchTabId === item.id ? 'active' : ''"
              v-for="item in mapSearch"
              :key="item.id"
              @click="getMapTab(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            class="noun-list"
            v-loading="loading"
            :class="searchMapList.length > 0 ? '' : 'empty'"
          >
            <div id="r-result">
              <div
                class="noun-list-item"
                :class="searchMapID === item.uid ? 'active' : ''"
                v-for="item in searchMapList"
                :key="item.uid"
                :id="`id${item.uid}`"
                @click="addLabel(item)"
              >
                <div class="left">
                  <div
                    class="left-title"
                    :class="searchMapID === item.uid ? 'active' : ''"
                    :title="item.title"
                  >
                    {{ item.title }}
                  </div>
                </div>
                <div
                  class="right"
                  :class="searchMapID === item.uid ? 'active' : ''"
                >
                  距离{{ item.distance }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-title" id="id4" style="margin-bottom: 16px">
        推荐房源
      </div>
      <div
        class="house-info"
        style="padding-bottom: 40px"
        v-if="Object.keys(details).length !== 0"
      >
        <div
          class="list-box-item"
          v-for="item in plantList"
          :key="item.id"
          @click="toPlantDetails(item)"
        >
          <div class="img">
            <el-image
              class="img"
              v-if="!item.coverImg"
              :src="require('../../../assets/no-img-list.png')"
              lazy
            >
              <div slot="error" class="image-slot">
                <img
                  style="width: 100%"
                  :src="'../../assets/no-img-list.png' + '?' + Date.now()"
                  alt=""
                />
              </div>
            </el-image>
            <el-image
              class="img"
              v-else
              :src="los + item.coverImg + '?' + Date.now()"
              lazy
            ></el-image>
          </div>
          <div class="list-content">
            <div class="list-content-title">{{ item.title }}</div>
            <div class="info">
              <div class="info-item">
                {{
                  item.totalArea !== item.minArea
                    ? `${item.minArea}-${item.totalArea}`
                    : item.totalArea
                }}㎡
              </div>
              <div class="line"></div>
              <div class="info-item">{{ item.buildingTypeName }}</div>
              <div class="line"></div>
              <div class="info-item">{{ item.structureTypeName }}</div>
            </div>
            <div class="label">
              <div
                class="label-item"
                v-for="(i, index) in labelFilter(item.labels)"
                :key="index"
              >
                {{ i }}
              </div>
            </div>
            <div class="count">
              {{ item.price
              }}<span class="price-unit"
                >元/㎡/月{{ item.price !== item.maxPrice ? '起' : '' }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="left">
        <img :src="los + user.headImg" alt="" />
        <div class="left-info">
          <div class="name">{{ user.name }}</div>
          <div class="company">{{ user.company }}</div>
        </div>
      </div>
      <div class="right">
        <el-button type="primary" @click="callPhone">电话联系</el-button>
        <!-- 回电弹窗 -->
        <div v-show="visible" class="code-box">
          <!-- <p>18874766241</p> -->
          <div class="c" ref="qrCodeDiv"></div>
          <p>扫码回电更快捷</p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { letDetail, letRecommend } from 'services/factory'
import QRCode from 'qrcodejs2';//引入生成二维码插件
import { getOpenUser } from 'services/broker'
import logo1 from '../../../assets/logo1.png'
import DetailsCarousel from 'components/plant/mobile/DetailsCarousel.vue';
import DetailsHeaderInfo from 'components/plant/mobile/DetailsHeaderInfo.vue'
import BMap from 'components/map/mobile/BMap.vue';
const los = window.los
export default {
  name: 'PlantDetails',
  components: {
    DetailsCarousel,
    DetailsHeaderInfo,
    BMap
  },
  data () {
    return {
      los,
      adress: '',
      points: [],
      tab: [
        { id: 1, text: '房源信息' },
        { id: 2, text: '配套设施' },
        // { id: 3, text: '周边位置' },
        { id: 4, text: '推荐房源' },
      ],
      mapSearch: [
        {
          id: 1,
          name: '公交',
          keywords: ['公交站'],
          dataType: null
        },
        {
          id: 2,
          name: '地铁',
          keywords: ['地铁站'],
          dataType: 230105
        },
        {
          id: 3,
          name: '高速',
          keywords: ['高速路口'],
          dataType: null
        },
        {
          id: 4,
          name: '飞机',
          keywords: ['飞机场'],
          dataType: 230109
        },
        {
          id: 5,
          name: '火车',
          keywords: ['火车站'],
          dataType: 230101
        },

        {
          id: 6,
          name: '物流',
          keywords: ['物流', '快递站', '菜鸟驿站'],
          dataType: 230111
        },
        {
          id: 7,
          name: '餐饮',
          keywords: ['餐饮'],
          dataType: 110100
        },
      ],
      mapSearchTabId: 1,
      loading: false,
      searchMapList: [],
      searchMapID: null,
      tabIndex: 1,
      tabVisible: false,
      infoActive: '基本信息',
      details: {},
      plantList: [],
      user: {},
      visible: false,
      isDown: true,
      headerTop: -44,
      mainTop: 308,
      mainStartTop: 308,
      startTop: 0, // 鼠标起始位置
      moveY: 0, // 鼠标移动距离
    }
  },
  watch: {
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (from.name === 'vr') vm.$router.go(0)
    });
  },
  mounted () {

    let that = this
    const city = localStorage.city


    const main = this.$refs.main; // 获取容器元素
    main.addEventListener('touchstart', (e) => {

      console.log(e);
      that.startTop = e.changedTouches[0].pageY
    });
    main.addEventListener('touchmove', (e) => {
      // e.preventDefault()
      let moveEndY = event.changedTouches[0].pageY
      let Y = moveEndY - this.startTop  //如果值为正,则代表手指下滑,反则则为上滑,为0则表示点击
      if (Y > 0 && that.mainTop >= 308) return
      if (that.mainTop <= 44) {
        // main.scrollTop = this.scrollTop - Y
      }
      if (Y < 0 && that.mainTop <= 44) {
        return
      }

      // 监听main是否滚动到顶了
      if (!main.scrollTop) {
        that.isDown = true
      } else {
        that.isDown = false
      }
      if (!that.isDown) return
      if (that.mainTop < 44) {
        that.isDown = false
        that.mainTop = 44
        this.mainStartTop = 44
      } else if (that.mainTop > 308) {
        that.isDown = false
        that.mainTop = 308
        this.mainStartTop = 308
      } else {
        that.isDown = true
        if (this.mainStartTop === 308) {
          that.moveY = that.startTop - e.changedTouches[0].pageY
          that.mainTop = 308 - that.moveY
        }
        if (this.mainStartTop === 44) {
          that.moveY = that.startTop - e.changedTouches[0].pageY
          that.mainTop = 44 - that.moveY
        }
      }
    }, false);
    main.addEventListener('touchend', () => {
      this.scrollTop = main.scrollTop
      if (!that.isDown) return
      if (that.mainStartTop === 308) {
        if (that.moveY > 100) {
          that.mainTop = 44
          that.mainStartTop = 44
          that.headerTop = 0
        } else {
          that.mainTop = 308
          that.mainStartTop = 308
          that.headerTop = -44
        }
      } else if (that.mainStartTop === 44) {
        if (that.moveY > 0) {
          that.mainTop = 44
          that.mainStartTop = 44
          that.headerTop = 0
        } else {
          that.mainTop = 308
          that.mainStartTop = 308
          that.headerTop = -44
        }
      }
      console.log('44-------------------', that.mainStartTop, main.scrollTop);
    });

    // 移动切换pc
    let bool = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    if (!bool) {
      this.$router.push(`/plantDetails/${this.$route.params.detailId || ''}`)
    }
    let detailId = this.$route.params.detailId

    // 判断厂房是否存在模型、全景
    let vm = this
    fetch(window.modelDataIp + "/modelData.json").then(function (response) {
      return response.json();
    }).then(data => {
      vm.$store.commit('setModelData', data)
      vm.getDetails(detailId)
      vm.getLetRecommend(JSON.parse(city).cityId)
    })
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    getMapSearchList (s) {
      this.loading = false
      this.searchMapList = s.map((item, index) => {
        return {
          ...item,
          uid: index
        }
      })
    },
    getMapTab (item) {
      console.log('item', item);
      this.searchMapID = null
      this.mapSearchTabId = item.id
      this.loading = true
      this.$refs.map.getSearchs(item)
    },
    addLabel (item) {
      this.searchMapID = item.uid
      var element = document.getElementById(`id${item.uid}`);
      const main = document.getElementById('r-result')
      console.log(element.offsetTop, main.scrollTop);
      main.scrollTo({
        top: element.offsetTop - 73,
        behavior: 'smooth'
      })
      this.$refs.map.addLabel(item)
    },
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    setInfoTab (str) {
      this.infoActive = str
    },
    getDetails (id) {
      letDetail({ id }).then(res => {
        if (res.data.status === 200) {
          this.details = res.data.data
          localStorage.HOUSE_DETAILS = JSON.stringify(res.data.data)
          this.getUser()

          let that = this

          // const city = localStorage.city
          this.$nextTick(() => {
            // 滚动监听
            const main = that.$refs.main; // 获取容器元素
            const id1 = document.getElementById('id1')
            const id2 = document.getElementById('id2')
            const id4 = document.getElementById('id4')
            main.addEventListener('scroll', () => {
              if (main.scrollTop > id1.offsetTop - 40) {
                that.tabVisible = true
                if (main.scrollTop < id2.offsetTop - 40) {
                  that.tabIndex = 1
                }
                if (main.scrollTop > id2.offsetTop - 40 && main.scrollTop < id4.offsetTop - 40) {
                  that.tabIndex = 2
                }
                if (main.scrollTop > id4.offsetTop - 40) {
                  that.tabIndex = 4
                }
              } else {
                that.tabVisible = false
              }
            })

            let mapBox = document.getElementById('mapBox')
            mapBox.addEventListener('touchstart', e => {
              console.log('点击了地图', e);
            })


            // 地图点位定位

            that.timer = setTimeout(() => {
              // that.adress = JSON.parse(city).name + that.details.district
              that.points = [that.details.showLng, that.details.showLat]
              that.$nextTick(() => {
                that.$refs.map.addMark(...that.points)
                that.$refs.map.getMark(...that.points, that.details.title)

                that.getMapTab(that.mapSearch[0])
                clearTimeout(that.timer)
              })
            }, 500)
          })

        }
      })
    },
    tabItem (id) {
      this.$refs.DetailsInfo.getStep(id)
    },
    scrollTo (top) {
      const main = document.getElementById('details')
      console.log(main);
      main.scrollTo({
        top: top - 120,
        behavior: 'smooth'
      })
    },
    // 获取推荐房源
    getLetRecommend (cityId) {
      letRecommend({ cityId }).then(res => {
        if (res.data.status === 200) {
          this.plantList = res.data.data
        }
      })
    },
    getUser () {
      let params = {
        brokerId: this.details.brokerId
      };
      getOpenUser(params).then(res => {
        if (res.data.status === 200) {
          this.user = {
            ...res.data.data,
            headImg: res.data.data.headImg + '?' + Date.now()
          }
          let text = ''
          if (res.data.data.secretPhoneExpiration) {
            if (res.data.data.secretPhoneExpiration === res.data.data.secretPhone) {
              text = res.data.data.secretPhone
            } else {
              text = `${res.data.data.secretPhone},,${res.data.data.secretPhoneExpiration}`
            }
          } else {
            text = res.data.data.secretPhone
          }
          this.openCode(`${window.phoneCall}${text}`)
        }
      })
    },
    callPhone () {
      window.location.href = 'tel://' + `${this.user.secretPhone}${!this.user.secretPhoneExpiration ? '' : ',,' + this.user.secretPhoneExpiration}`
    },
    openCode (text) {
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
        let qrcode = new QRCode(this.$refs.qrCodeDiv, {
          text,//二维码链接，参数是否添加看需求
          width: 88,//二维码宽度
          height: 88,//二维码高度
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
        });
        let logo = new Image();
        logo.crossOrigin = 'Anonymous';
        logo.src = logo1
        // let con = this.$refs.qrCodeDiv;
        logo.onload = function () {
          // let container = con;
          let qrImg = qrcode._el.getElementsByTagName('img')[0];
          let canvas = qrcode._el.getElementsByTagName('canvas')[0];
          var ctx = canvas.getContext("2d");
          ctx.drawImage(logo, 88 * 0.5 - 11, 88 * 0.5 - 11, 22, 22);
          qrImg.src = canvas.toDataURL();
          // container.appendChild(qrcode._el);
        }
      })
    },
    scrollTab (item) {
      const main = this.$refs.main; // 获取容器元素
      const element = document.getElementById(`id${item.id}`)
      main.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      })
    },
    toPlantDetails (item) {
      // alert('aaaa', item.id)
      this.$router.replace({
        path: '/plantDetails_m' + "/" + item.id,
      }, () => { })

      // let routeUrl = this.$router.resolve({
      //   path: '/plantDetails_m',
      //   data: {
      //     item: JSON.stringify(item)
      //   }
      // })
      // window.open(routeUrl.href + "/" + item.id, '_blank');
    },
  }
};
</script>
<style lang="less" scoped>
.plant-details {
  min-width: 0 !important;
  position: relative;
  .header {
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 44px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    padding-left: 12px;
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .main {
    position: absolute;
    bottom: 0;
    z-index: 20;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    background: rgba(249, 248, 247, 1);
    .tab {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: fixed;
      top: 43px;
      z-index: 20;
      height: 40px;
      opacity: 1;
      width: 100%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.02);
      &-item {
        font-size: 14px;
        font-weight: 500;
        color: rgba(153, 153, 153, 1);
        &.active {
          color: rgba(236, 102, 0, 1);
          text-align: center;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            width: 16px;
            height: 3px;
            opacity: 1;
            border-radius: 8px;
            background: linear-gradient(
              180deg,
              rgba(236, 102, 0, 0) 0%,
              rgba(236, 102, 0, 1) 100%
            );
          }
        }
      }
    }
    .main-title {
      padding: 12px 20px 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 22.4px;
      color: rgba(51, 51, 51, 1);
    }
    .house-info {
      padding: 0px 8px;
      border-radius: 6px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 4px rgba(236, 102, 0, 0.04);
      padding: 0 12px;
      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding-top: 8px;
        margin-bottom: 8px;
        &::before {
          content: '';
          display: flex;
          width: 3px;
          height: 14px;
          opacity: 1;
          border-radius: 4px;
          margin-right: 4px;
          background: linear-gradient(
            180deg,
            rgba(236, 102, 0, 1) 0%,
            rgba(236, 102, 0, 0) 100%
          );
        }
      }
      .content {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(51, 51, 51, 1);
      }
      .info-detail {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &-item {
          width: 50%;
          line-height: 30px;
          display: flex;
          label {
            width: 60px;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20px;
            color: rgba(153, 153, 153, 1);
            // margin-right: 24px;
          }
          .p {
            width: calc(100% - 60px);
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20px;
            color: rgba(51, 51, 51, 1);
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: wrap;
            word-wrap: break-word;
          }
        }
      }
      .feature {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &-item {
          padding: 4px 8px;
          border-radius: 4px;
          background: #fcf4e6;
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          color: rgba(247, 162, 25, 1);
          margin-right: 6px;
          margin-bottom: 12px;
        }
      }
      .facility {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        .facility-item {
          width: 25%;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          img.active {
            filter: grayscale(90%);
          }
          .text {
            margin-left: 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(51, 51, 51, 1);
            .state {
              color: rgba(187, 187, 187, 1);
            }
          }
        }
      }
      .map {
        height: 150px;
      }
      .noun {
        height: 150px;
        &-search {
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 12px;
          &-item {
            width: 40px;
            height: 20px;
            border-radius: 4px;
            opacity: 1;
            background: rgba(245, 245, 245, 1);
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: rgba(51, 51, 51, 1);
            cursor: pointer;
            &.active {
              background: rgba(255, 237, 224, 1);
              color: rgba(236, 102, 0, 1);
            }
          }
        }
        &-list {
          height: 100px;
          overflow-y: scroll;
          &#r-result {
            & > div {
              border: 0 !important;
            }
          }
          &-item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(245, 245, 245, 1);
            padding: 6px 20px;
            cursor: pointer;
            &.active {
              background-color: rgba(255, 249, 242, 1);
            }
            .left {
              width: 60%;
              &-title {
                font-size: 13px;
                font-weight: 400;
                line-height: 18.82px;
                color: rgba(51, 51, 51, 1);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &.active {
                  color: #ec6600;
                }
              }
            }
            .right {
              text-align: right;
              width: 40%;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 0px;
              line-height: 17.38px;
              color: rgba(16, 118, 235, 1);
            }
          }
        }
      }

      .list-box {
        &-item {
          height: 127px;
          opacity: 1;
          border-radius: 6px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 4px rgba(236, 102, 0, 0.04);
          margin-bottom: 8px;
          padding: 11px;
          display: flex;
          .img {
            width: 140px;
            height: 105px;
            margin-right: 8px;
            border-radius: 4px;
            border: 1px solid rgba(238, 238, 238, 1);
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .list-content {
            width: calc(100% - 150px);
            &-title {
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0px;
              line-height: 18px;
              color: rgba(51, 51, 51, 1);
              margin-bottom: 4px;
            }
            .info {
              display: flex;
              align-items: center;
              margin-bottom: 6px;
              &-item {
                font-size: 10px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 14px;
                color: rgba(153, 153, 153, 1);
                &:nth-child(1) {
                  color: rgba(5, 105, 255, 1);
                }
              }
              .line {
                margin: 0 4px;
                width: 1px;
                height: 10px;
                background-color: rgba(238, 238, 238, 1);
              }
            }
            .label {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              &-item {
                padding: 0 7px;
                height: 20px;
                opacity: 1;
                border-radius: 2px;
                background: rgba(255, 255, 255, 1);
                border: 0.6px solid rgba(255, 233, 199, 1);
                font-size: 10px;
                font-weight: 500;
                line-height: 16px;
                color: rgba(247, 152, 0, 1);
                text-align: center;
                margin-right: 4px;
              }
            }
            .count {
              font-size: 17px;
              font-weight: 700;
              line-height: 23.8px;
              color: rgba(236, 102, 0, 1);
            }
          }
        }
      }
    }
  }
  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    z-index: 50;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.03);
    padding: 0 12px;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 8px;
      }
      &-info {
        .name {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 16.08px;
          color: rgba(0, 0, 0, 1);
          margin-bottom: 8px;
        }
        .company {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 12.06px;
          color: rgba(136, 136, 136, 1);
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: end;
      .btn {
        border-radius: 4px;
        background: rgba(255, 237, 224, 1);
        border: 0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(236, 102, 0, 1);
      }
      /deep/ .el-button {
        font-size: 16px;
        font-weight: 400;
        height: 38px;
      }

      .code-box {
        position: absolute;
        top: -140px;
        right: 10px;
        padding: 10px 20px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        .c {
          width: 88px;
          height: 88px;
          margin-bottom: 4px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          &:first-child {
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            color: rgba(51, 51, 51, 1);
          }
          &:last-child {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }
  }
}
</style>
  