<template>
  <div
    class="plant-map-details"
    :style="{
      right: show ? '0' : '-420px'
    }"
  >
    <div class="switch" @click="switchList">
      <i :class="show ? 'el-icon-caret-right' : 'el-icon-caret-left'"></i>
    </div>
    <div class="list">
      <el-carousel
        v-if="!plantDetails.images"
        ref="img"
        trigger="click"
        :autoplay="false"
        height="360px"
      >
        <el-carousel-item>
          <img
            style="width: 100%; height: 100%"
            :src="require('../../assets/no-img-details.png')"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
      <el-carousel
        v-else
        ref="img"
        trigger="click"
        :autoplay="false"
        height="360px"
      >
        <el-carousel-item
          v-for="(item, index) in plantDetails.images"
          :key="index"
        >
          <img
            style="width: 100%; height: 100%"
            :src="los + item + '?' + Date.now()"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
      <main>
        <div class="code">
          <div class="count">
            <span class="report">
              {{ plantDetails.title }}
            </span>
          </div>

          <div class="count">
            <div class="label">
              <div
                class="label-item"
                v-for="(i, index) in labelFilter(plantDetails.labels)"
                :key="index"
                :title="i"
              >
                {{ i }}
              </div>
            </div>
            <span class="num"
              >{{ plantDetails.price
              }}<span class="price-unit"
                >元/㎡/月{{
                  plantDetails.price !== plantDetails.maxPrice ? '起' : ''
                }}</span
              ></span
            >
          </div>
        </div>

        <div class="label-info">
          <div class="">
            <div class="text1">{{ plantDetails.totalArea || '-' }}m²</div>
            <div class="text2">总面积</div>
          </div>
          <div class="">
            <div class="text1">{{ plantDetails.buildingTypeName || '-' }}</div>
            <div class="text2">厂房类型</div>
          </div>
          <div class="">
            <div class="text1">{{ plantDetails.structureTypeName || '-' }}</div>
            <div class="text2">厂房结构</div>
          </div>
          <div class="">
            <div class="text1">{{ plantDetails.minArea || '-' }}m²</div>
            <div class="text2">起租面积</div>
          </div>
        </div>
        <div class="info">
          <div class="title">基本信息</div>
          <div class="info-content">
            <div class="content">
              <div class="content-item">
                <label for="">房源编号：</label>
                <span>{{ plantDetails.buildingNo || '-' }}</span>
              </div>
              <div class="content-item">
                <label for="">新旧程度：</label>
                <span>{{ plantDetails.yearType || '-' }}</span>
              </div>
              <div class="content-item">
                <label for="">区域：</label>
                <span
                  >{{ plantDetails.district }} - {{ plantDetails.street }}</span
                >
              </div>
              <div class="content-item">
                <label for="">楼层：</label>
                <span>{{ plantDetails.floorTypeName || '-' }}</span>
              </div>
              <div class="content-item">
                <label for="">出租方式：</label>
                <span>{{ plantDetails.sublet ? '分租' : '整租' }}</span>
              </div>
            </div>
            <img src="../../assets/xcx-code.png" alt="" />
          </div>
        </div>
      </main>
      <div class="footer">
        <div class="left">
          <img :src="los + user.headImg + '?' + Date.now()" alt="" />
          <div class="left-info">
            <div class="name">{{ user.name }}</div>
            <div class="company">{{ user.company }}</div>
          </div>
        </div>
        <div class="right">
          <el-button class="btn" @click="toPlantDetails(plantDetails)"
            >查看详情</el-button
          >
          <el-button type="primary" @click="visible = !visible"
            >电话联系</el-button
          >
          <!-- 回电弹窗 -->
          <div v-show="visible" class="code-box">
            <!-- <p>18874766241</p> -->
            <div class="c" ref="qrCodeDiv"></div>
            <p>扫码回电更快捷</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
const los = window.los
import QRCode from 'qrcodejs2';//引入生成二维码插件
import { getOpenUser } from 'services/broker'
import logo1 from '../../assets/logo1.png'
export default {
  name: 'PlantMapDetails',
  props: {
    plantDetails: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {
      los,
      show: false,
      user: {},
      visible: false,
    }
  },
  watch: {
    plantDetails (val) {
      this.getUser(val)
    }
  },
  methods: {
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    getUser () {
      let params = {
        brokerId: this.plantDetails.brokerId
      };
      getOpenUser(params).then(res => {
        if (res.data.status === 200) {
          this.user = res.data.data
          let text = ''
          if (res.data.data.secretPhoneExpiration) {
            if (res.data.data.secretPhoneExpiration === res.data.data.secretPhone) {
              text = res.data.data.secretPhone
            } else {
              text = `${res.data.data.secretPhone},,${res.data.data.secretPhoneExpiration}`
            }
          } else {
            text = res.data.data.secretPhone
          }
          this.openCode(`${window.phoneCall}${text}`)
        }
      })
    },
    openCode (text) {
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
        let qrcode = new QRCode(this.$refs.qrCodeDiv, {
          text,//二维码链接，参数是否添加看需求
          width: 88,//二维码宽度
          height: 88,//二维码高度
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
        });
        let logo = new Image();
        logo.crossOrigin = 'Anonymous';
        logo.src = logo1
        let con = this.$refs.qrCodeDiv;
        logo.onload = function () {
          let qrImg = qrcode._el.getElementsByTagName('img')[0];
          let canvas = qrcode._el.getElementsByTagName('canvas')[0];
          var ctx = canvas.getContext("2d");
          ctx.drawImage(logo, 88 * 0.5 - 11, 88 * 0.5 - 11, 22, 22);
          qrImg.src = canvas.toDataURL();
          con.appendChild(qrImg);
        }
      })
    },
    toPush (item) {
      this.$router.push({
        path: item.url
      })
    },
    switchList () {
      this.show = !this.show
    },
    toPlantDetails (item) {
      let routeUrl = this.$router.resolve({
        path: '/plantDetails',
        data: {
          item: JSON.stringify(item)
        }
      })
      window.open(routeUrl.href + "/" + item.id, '_blank');
    },
  }
};
    </script>
    
    <style lang="less" scoped>
.plant-map-details {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  // width: 420px;
  height: 100%;
  display: flex;
  align-items: center;
  transition-property: all;
  transition-duration: 0.4s;
  .switch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 80px;
    border-radius: 8px 0px 0px 8px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(238, 238, 238, 1);
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    font-size: 22px;
    color: rgba(102, 102, 102, 1);
  }

  .list {
    width: 420px;
    height: 100%;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    .img {
    }
    main {
      padding: 0 15px;
      .code {
        img {
          width: 120px;
          height: 120px;
        }
      }
      .count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        .num {
          width: 120px;
          text-align: end;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color: rgba(236, 102, 0, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .report {
          width: 100%;
          cursor: pointer;
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .label {
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // margin-bottom: 24px;
        &-item {
          padding: 3px 10px;
          border-radius: 2px;
          background: #f6f5f4;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: rgba(153, 153, 153, 1);
          margin-right: 12px;
        }
      }
      .label-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
        margin-bottom: 16px;
        .text1 {
          font-size: 16px;
          font-weight: 500;
          line-height: 26px;
          color: rgba(51, 51, 51, 1);
          text-align: center;
        }
        .text2 {
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          color: rgba(136, 136, 136, 1);
          text-align: center;
        }
      }
      .info {
        .title {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 23.17px;
          color: rgba(0, 0, 0, 1);
          margin-bottom: 8px;
        }
        &-content {
          display: flex;
          justify-content: space-between;
          .content {
            width: calc(100%-120px);
            &-item {
              width: 100%;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              label {
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 21.72px;
                color: rgba(136, 136, 136, 1);
                width: 78px;
              }
              span {
                width: calc(100% - 78px);
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 21.72px;
                color: rgba(51, 51, 51, 1);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          img {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
      .left {
        display: flex;
        align-items: center;
        img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          margin-right: 8px;
        }
        &-info {
          .name {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 16.08px;
            color: rgba(0, 0, 0, 1);
            margin-bottom: 8px;
          }
          .company {
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 12.06px;
            color: rgba(136, 136, 136, 1);
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: end;
        .btn {
          border-radius: 4px;
          background: rgba(255, 237, 224, 1);
          border: 0;
          font-size: 18px;
          font-weight: 400;
          color: rgba(236, 102, 0, 1);
        }
        /deep/ .el-button {
          font-size: 18px;
          font-weight: 400;
          height: 48px;
        }
      }
    }
    /deep/ .el-carousel__indicators--horizontal {
      display: none;
    }
    /deep/ .el-carousel__arrow {
      width: 38px;
      height: 70px;
      border-radius: 0;
      background: #33302e;
      &--left {
        left: 0;
      }
      &--right {
        right: 0;
      }
    }
    /deep/ .el-carousel__arrow i {
      font-size: 40px;
    }
  }
}

.code-box {
  position: absolute;
  top: -140px;
  right: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  .c {
    width: 88px;
    height: 88px;
    margin-bottom: 4px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    &:first-child {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      color: rgba(51, 51, 51, 1);
    }
    &:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
    