import axios from '../plugins/factory';

import qs from 'qs';

// form 表单传参
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data, { arrayFormat: 'repeat' });
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};
// 获取搜索参数
const searchLabel = () => axios.get('/search/label');

// 搜索厂房接口
const searchPage = params =>
  axios.get('/search/page', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 查询房源详情
const letDetail = params =>
  axios.get('/let/detail', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 租厂房主页搜索标签
const homeSearchLabel = params =>
  axios.get('/search/label/home', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 获取房源推荐
const letRecommend = params =>
  axios.get('/let/recommend', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 举报房源
const reportBuilding = async data =>
  axios.post('/building/report/submit', data, form);

// 获取经纪人房源
const letBuildBroker = params =>
  axios.get('/let/building/broker', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 委托找房-提交
const submitFindBuilding = async data =>
  axios.post('/entrust/find/building/submit', data, form);

// 按矩形范围搜索厂房（上图）
const searchRect = params =>
  axios.get('/search/rect', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

// 按矩形范围搜索厂房(分页)
const searchRectPage = params =>
  axios.get('/search/rect/page', {
    params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  });

export {
  searchLabel,
  searchPage,
  letDetail,
  homeSearchLabel,
  letRecommend,
  reportBuilding,
  letBuildBroker,
  submitFindBuilding,
  searchRect,
  searchRectPage
};
