import { render, staticRenderFns } from "./DetailsHeaderInfo.vue?vue&type=template&id=32b93178&scoped=true&"
import script from "./DetailsHeaderInfo.vue?vue&type=script&lang=js&"
export * from "./DetailsHeaderInfo.vue?vue&type=script&lang=js&"
import style0 from "./DetailsHeaderInfo.vue?vue&type=style&index=0&id=32b93178&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b93178",
  null
  
)

export default component.exports