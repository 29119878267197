import { render, staticRenderFns } from "./WithdrawDepositDialog.vue?vue&type=template&id=37e0b570&scoped=true&"
import script from "./WithdrawDepositDialog.vue?vue&type=script&lang=js&"
export * from "./WithdrawDepositDialog.vue?vue&type=script&lang=js&"
import style0 from "./WithdrawDepositDialog.vue?vue&type=style&index=0&id=37e0b570&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37e0b570",
  null
  
)

export default component.exports