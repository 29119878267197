<template>
  <div class="details-carousel">
    <div class="img-big">
      <SpecialOffer
        v-show="details.preferential"
        :label="details.preferential"
      />
      <el-carousel
        v-if="imgList.length > 0"
        class="img-big-box"
        ref="img"
        trigger="click"
        :autoplay="false"
        height="533px"
        @change="setImg"
      >
        <el-carousel-item v-for="(url, index) in imgList" :key="index">
          <img
            :src="los + url.url + '?' + Date.now()"
            alt=""
            @click="selBigImg(url)"
          />
          <div @click="toType">
            <img
              class="gif"
              v-if="type === 1 && modelExist && index === 0"
              src="../../assets/plant/details/3D.png"
              alt=""
            />
            <img
              class="gif"
              v-if="type === 2 && vrExist && index === 0"
              src="../../assets/plant/details/VR.png"
              alt=""
            />
            <img
              class="gif"
              v-if="type === 3 && videoExist && index === 0"
              src="../../assets/plant/details/video.png"
              alt=""
            />
          </div>
          <div
            class="look-house-type"
            v-show="(modelExist || vrExist || videoExist) && index == 0"
          >
            <div
              v-if="modelExist"
              class="type-item"
              :class="type === 1 ? 'active' : ''"
              @click="selType(1)"
            >
              三维
            </div>
            <div class="line"></div>
            <div
              v-if="vrExist"
              class="type-item"
              :class="type === 2 ? 'active' : ''"
              @click="selType(2)"
            >
              全景
            </div>
            <div
              v-if="videoExist"
              class="type-item"
              :class="type === 3 ? 'active' : ''"
              @click="selType(3)"
            >
              视频
            </div>
            <div
              class="type-item"
              :class="type === 4 ? 'active' : ''"
              @click="selType(4)"
            >
              图片
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

      <el-carousel
        v-else
        class="img-big-box"
        ref="img"
        trigger="click"
        :autoplay="false"
        height="533px"
        @change="setImg"
      >
        <el-carousel-item>
          <img :src="require('../../assets/no-img-details.png')" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="carousel">
      <i class="el-icon-arrow-left" @click="left"></i>
      <main>
        <div
          v-if="details.coverImg"
          class="img-box"
          :style="{ transform: `translateX(${length}px)` }"
        >
          <div class="img" v-for="(url, index) in imgList" :key="index">
            <el-image
              @click="selImg(url)"
              :class="imgIndex === url.id ? 'imgActive' : ''"
              :src="los + url.url"
              class="carousel-img"
              lazy
            ></el-image>
          </div>
        </div>
        <div
          v-else
          class="img-box"
          :style="{ transform: `translateX(${length}px)` }"
        >
          <div class="img">
            <el-image
              :src="require('../../assets/no-img-details.png')"
              class="carousel-img imgActive"
              lazy
            >
              <div slot="error" class="image-slot">
                <img
                  style="width: 100%"
                  :src="'../../assets/no-img-details.png' + '?' + Date.now()"
                  alt=""
                />
              </div>
            </el-image>
          </div>
        </div>
      </main>
      <i class="el-icon-arrow-right" @click="right"></i>
    </div>
    <DialogImg ref="dialogImg" entrance="" :details="details" />
  </div>
</template>
<script>
import SpecialOffer from 'components/other/SpecialOffer.vue';
import DialogImg from './DialogImg.vue';
const los = window.los
export default {
  name: 'DetailsCarousel',
  components: {
    SpecialOffer,
    DialogImg
  },
  props: {
    details: Object
  },
  data () {
    return {
      los,
      url: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
      imgIndex: 1,
      type: 1,
      img: [],
      length: null,
      count: 1,
      imgList: [],
      modelExist: true,
      vrExist: true,
      videoExist: false,
      imgVisible: true,
    }
  },
  mounted () {
    let arr = []
    this.$nextTick(() => {
      if (this.$store.state.modelData.data[this.details.id]) {
        let { url1, panoramaUrl } = this.$store.state.modelData.data[this.details.id]
        if (!url1) this.modelExist = false
        if (!panoramaUrl) this.vrExist = false
        arr = [{ url: this.details.coverImg, type: 1 }]
      } else {
        this.modelExist = false
        this.vrExist = false
        this.videoExist = false
      }

      // 判断是否存在模型、全景、视频
      // if (this.modelExist) arr.push({ url: this.details.coverImg, type: 1 })
      // if (this.vrExist) arr.push({ url: this.details.coverImg, type: 1 })
      // if (this.videoExist) arr.push({ url: this.details.coverImg, type: 1 })
      if (this.details.images)
        arr = arr.concat(this.details.images || [])
      this.img = arr.map((item, index) => {
        return {
          url: (item.url || item) + '?' + Date.now(),
          id: index + 1,
          type: item.type || 4
        }
      })
      this.url = this.details.coverImg
      this.imgList = this.img
    })
  },
  methods: {
    toType () {
      console.log('aaaa')
      if (this.type === 1) this.toModel()
      if (this.type === 2) this.toVR()
    },
    selType (type) {
      this.type = type
      if (type === 4) this.right()
    },
    toVR () {
      this.type = 2;
      let routeUrl = this.$router.resolve({
        path: '/vr',
      })
      window.open(routeUrl.href + '/3/' + this.details.id, '_blank');
    },
    toModel () {
      this.type = 1;
      let routeUrl = this.$router.resolve({
        path: '/model',
      })
      window.open(routeUrl.href + '/1/' + this.details.id, '_blank');
    },
    selBigImg (item) {
      if (item.type === 4) {
        this.$refs.dialogImg.index = item.id - 1
        this.$refs.dialogImg.imgVisible = true
        this.$nextTick(() => {
          this.$refs.dialogImg.selImg(item)
        })
      } else {
        this.toType()
      }
    },
    selImg (item) {
      this.imgIndex = item.id;
      console.log(item.type, this.count);
      if (this.count > item.id) {
        const num = this.count - item.id
        for (let index = 0; index < num; index++) {
          this.$refs.img.prev()
          this.type = item.type
        }
      } else if (this.count < item.id) {
        const num = item.id - this.count
        for (let index = 0; index < num; index++) {
          this.$refs.img.next()
          this.type = item.type
        }
      }
      this.count = item.id
    },
    left () {
      if (this.img.length > 0) {
        this.count -= 1;
        this.$refs.img.prev()
        this.imgIndex = this.count
        if (!this.count) {
          this.count = this.img.length;
          this.imgIndex = this.count
          this.length = this.img.length > 6 ? -(this.count - 6) * 104 : this.length
        }
        if (this.img.length > 6) {
          let a = this.img.length - 5
          if (this.count < a) {
            this.length = (-this.count + 1) * 104
          }
        }
      }
    },
    right () {
      if (this.img.length > 0) {
        this.count += 1;
        this.$refs.img.next()
        this.imgIndex = this.count
        if (this.img.length > 6) {
          if (this.count > 6) {
            this.length = (-this.count + 6) * 104
          }
        }
        if (this.count > this.img.length) {
          this.count = 1;
          this.imgIndex = this.count
          this.length = this.img.length > 6 ? 0 : this.length
        }
      }
    },
    setImg (val) {
      console.log(val);
      this.count = val + 1
      this.imgIndex = this.count
      if (!this.count) {
        this.count = this.img.length;
        this.imgIndex = this.count
        this.length = this.img.length > 6 ? -(this.count - 6) * 104 : this.length
      }
      if (this.img.length > 6) {
        let a = this.img.length - 5
        if (this.count < a) {
          this.length = (-this.count + 1) * 104
        }
      }
      if (this.img.length > 6) {
        if (this.count > 6) {
          this.length = (-this.count + 6) * 104
        }
      }
      if (this.count > this.img.length) {
        this.count = 1;
        this.imgIndex = this.count
        this.length = this.img.length > 6 ? 0 : this.length
      }
      this.type = this.imgList[val].type
    },
  }
};
</script>
<style lang="less" scoped>
.details-carousel {
  .img-big {
    width: 100%;
    height: 533px;
    margin-bottom: 12px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    &-box {
      position: relative;
      .look-house-type {
        position: absolute;
        z-index: 100;
        background: rgba(0, 0, 0, 0.36);
        overflow: hidden;
        border-radius: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        .type-item {
          padding: 0 16px;
          font-size: 14px;
          height: 32px;
          color: rgba(255, 255, 255, 1);
          line-height: 32px;
          &.active {
            // padding: 0 16px;
            height: 30px;
            line-height: 30px;
            border-radius: 20px;
            background: #000;
          }
        }
        .line {
          width: 1px;
          height: 100%;
          background: #eee;
        }
      }
      .gif {
        position: absolute;
        z-index: 100;
        width: 90px;
        height: 90px;
        opacity: 1;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
    /deep/ .special-offer {
      width: 136px;
      height: 50px;
      background: rgba(247, 162, 25, 1);
      border: 1px solid rgba(247, 162, 25, 1);
      box-shadow: 0px 2px 2px rgba(38, 24, 0, 0.16);
      font-size: 24px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      line-height: 50px;
      left: 0;
      padding: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .carousel {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    .el-icon-arrow-left {
      width: 38px;
      height: 70px;
      color: #fff;
      font-size: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      background: #33302e;
    }
    main {
      width: calc(100% - 76px);
      margin: 0 10px;
      height: 70px;
      overflow: hidden;
      .img-box {
        display: flex;
        align-items: center;
        transition: all 0.3s ease;
        height: 70px;
        .img {
          height: 70px;
          &:first-child {
            img {
              margin-left: 0;
            }
          }
        }
      }
    }
    .el-icon-arrow-right {
      width: 38px;
      height: 70px;
      color: #fff;
      font-size: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      background: #33302e;
    }
  }
  .carousel-img {
    width: 94px;
    height: 70px;
    border-radius: 4px;
    margin-left: 10px;
    opacity: 0.5;
    cursor: pointer;
    &.imgActive {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }
  /deep/ .el-carousel__indicators--horizontal {
    display: none;
  }
  /deep/ .el-carousel__arrow {
    display: none;
    width: 38px;
    height: 70px;
    border-radius: 0;
    background: #33302e;
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
  /deep/ .el-carousel__arrow i {
    font-size: 40px;
  }
}
</style>