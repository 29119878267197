<template>
  <div
    class="plant-map-list"
    :style="{
      right: show ? '0' : '-440px'
    }"
  >
    <div class="switch" @click="switchList">
      <div>
        可视范围内为您找到<span>{{ total }}</span
        >套房源
      </div>
      <i :class="show ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
    </div>
    <div class="list" v-show="show" v-infinite-scroll="load">
      <div
        class="list-item"
        :class="activeId === item.id ? 'active' : ''"
        v-for="item in plantList"
        :key="item.id"
        @click="getItem(item)"
      >
        <div class="img">
          <el-image
            class="img"
            v-if="!item.coverImg"
            :src="require('../../assets/no-img-list.png')"
            lazy
          >
            <div slot="error" class="image-slot">
              <img
                style="width: 100%"
                :src="'../../assets/no-img-list.png' + '?' + Date.now()"
                alt=""
              />
            </div>
          </el-image>
          <el-image
            class="img"
            v-else
            :src="los + item.coverImg"
            lazy
          ></el-image>
        </div>
        <div class="item-content">
          <div class="item-content-title">
            <div class="p">
              <p :title="item.title">{{ item.title }}</p>
            </div>
          </div>
          <div class="item-content-main">
            <div class="label">
              <div class="label-line">
                <span>{{ item.buildingTypeName }}</span>
                <div class="line"></div>
                <span>{{ item.structureTypeName }}</span>
                <div class="line"></div>
                <span>{{ item.floorTypeName }}</span>
              </div>
              <div class="label-line">
                <span style="color: rgba(51, 51, 51, 1)"
                  >{{
                    item.totalArea !== item.minArea
                      ? `${item.minArea}-${item.totalArea}`
                      : item.totalArea
                  }}㎡</span
                >
                <div class="line"></div>
                <!-- <i class="icon icon-plant-details-location"></i> -->
                {{ item.district }}-{{ item.street }}
              </div>
            </div>
            <div class="count" :title="item.price">
              {{ item.price }}
              <span class="price-unit"
                >元/㎡/月{{ item.price !== item.maxPrice ? '起' : '' }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
const los = window.los
export default {
  name: 'PlantMapList',
  props: {
    plantList: {
      type: Array,
      default: () => []
    },
    totalPage: Number,
    total: Number,
    page: Number,
    activeId: String
  },
  data () {
    return {
      los,
      show: false,
      countPage: 1
    }
  },
  methods: {
    load () {
      console.log('total', this.total, this.page);
      this.countPage = this.page
      this.countPage += 1
      if (this.countPage > this.totalPage) return
      this.$emit('getList', this.countPage)

    },
    toPush (item) {
      this.$router.push({
        path: item.url
      })
    },
    switchList () {
      this.show = !this.show
    },
    getItem (item) {
      this.$parent.activeId = item.id
      this.$emit('getItem', item)
    }
  }
};
    </script>
    
    <style lang="less" scoped>
.plant-map-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 440px;
  height: 100%;
  transition-property: all;
  transition-duration: 0.4s;
  .switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    width: 440px;
    height: 54px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    cursor: pointer;
    span {
      font-size: 16px;
      font-weight: 500;
      color: rgba(236, 102, 0, 1);
    }
    i {
      font-size: 18px;
      font-weight: bold;
      color: #666;
    }
  }

  .list {
    width: 440px;
    height: calc(100% - 54px);
    overflow: auto;
    // padding: 20px 0;
    box-sizing: border-box;
    background-color: #fff;
    border-top: 1px solid #f5f5f5;
    &-item {
      display: flex;
      padding-bottom: 20px;
      // margin-bottom: 20px;
      cursor: pointer;
      padding: 14px 12px;
      &.active {
        background-color: #fff0e6;
      }
      .img {
        width: 94px;
        height: 70px;
        border-radius: 4px;
        border: 1px solid rgba(238, 238, 238, 1);
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-content {
        width: calc(100% - 102px);
        height: 35%;
        box-sizing: border-box;
        &-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .p {
            display: flex;
            width: 100%;
            p {
              // width: calc(100% - 64px);
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              color: rgba(51, 51, 51, 1);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span {
              display: inline-block;
              width: 64px;
              text-align: center;
              border-radius: 4px;
              background: rgba(0, 184, 12, 1);
              font-size: 12px;
              font-weight: 400;
              line-height: 24px;
              color: rgba(255, 255, 255, 1);
            }
          }
        }
        &-main {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          margin-top: 8px;
          .label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &-line {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: rgba(153, 153, 153, 1);
              &:first-child {
                margin-bottom: 4px;
              }
              .line {
                width: 1px;
                height: 14px;
                background-color: rgba(221, 221, 221, 1);
                margin: 0 7px;
              }
            }
          }
          .phone {
            width: 110px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: rgba(232, 242, 252, 1);
            font-size: 14px;
            font-weight: 400;
            color: rgba(16, 118, 235, 1);
            span {
              margin-left: 8px;
            }
          }
          .count {
            // width: 70px;
            margin-left: 38px;
            font-size: 16px;
            font-weight: 600;
            font-family: DIN;
            line-height: 24px;
            text-align: end;
            color: rgba(236, 102, 0, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .price-unit {
              font-size: 13px;
            }
          }
        }
        &-label {
          display: flex;
          align-items: center;
          &-item {
            padding: 3px 10px;
            border-radius: 2px;
            background: rgba(252, 244, 230, 1);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: rgba(247, 162, 25, 1);
            margin-right: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      // &:last-child {
      //   padding-bottom: 0;
      //   margin-bottom: 0;
      // }
    }
  }
}
</style>
    