<template>
  <div class="details-info-tab">
    <div
      :class="infoActive === '基本信息' ? 'active' : ''"
      class="details-info-tab-item"
      @click="$emit('tabItem', 'tab1')"
    >
      基本信息
    </div>
    <div
      :class="infoActive === '建筑详情' ? 'active' : ''"
      class="details-info-tab-item"
      @click="$emit('tabItem', 'tab2')"
    >
      建筑详情
    </div>
    <div
      :class="infoActive === '详细介绍' ? 'active' : ''"
      class="details-info-tab-item"
      @click="$emit('tabItem', 'tab3')"
    >
      详细介绍
    </div>
    <div
      :class="infoActive === '配套设施' ? 'active' : ''"
      class="details-info-tab-item"
      @click="$emit('tabItem', 'tab4')"
    >
      配套设施
    </div>
    <div
      :class="infoActive === '价格详情' ? 'active' : ''"
      class="details-info-tab-item"
      @click="$emit('tabItem', 'tab5')"
    >
      价格详情
    </div>
    <div
      :class="infoActive === '房源特色' ? 'active' : ''"
      class="details-info-tab-item"
      @click="$emit('tabItem', 'tab6')"
    >
      房源特色
    </div>
    <div
      :class="infoActive === '图片' ? 'active' : ''"
      class="details-info-tab-item"
      @click="$emit('tabItem', 'tab7')"
    >
      图片
    </div>
    <div
      :class="infoActive === '位置' ? 'active' : ''"
      class="details-info-tab-item"
      @click="$emit('tabItem', 'tab8')"
    >
      位置
    </div>
  </div>
</template>
  <script>
export default {
  name: 'DetailsInfoTab',
  data () {
    return {
      infoActive: '基本信息'
    }
  },
  mounted () {
  },
  methods: {
    setInfoTab (str) {
      this.infoActive = str
    }
  }
};
  </script>
  
  <style lang="less" scoped>
.details-info-tab {
  height: 70px;
  background: #f6f5f4;
  padding: 0 33px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: sticky;
  top: 0px;
  z-index: 99;
  &-item {
    margin-right: 72px;
    font-size: 18px;
    height: 26px;
    line-height: 26px;
    color: rgba(102, 102, 102, 1);
    cursor: pointer;
    box-sizing: border-box;
    &.active {
      color: rgba(51, 51, 51, 1);
      font-weight: 600;
      &::after {
        content: '';
        margin: 3px auto;
        display: block;
        width: 24px;
        height: 3px;
        border-radius: 8px;
        background: #ec6600;
      }
    }
  }
}
</style>
  