<template>
  <div class="search">
    <!-- <header>
      <div
        class="nav-item"
        :class="navIndex === item.id ? 'active' : ''"
        v-for="item in nav"
        :key="item.id"
        @click="navIndex = item.id"
      >
        <img :src="item.url" alt="" />
        <span>{{ item.name }}</span>
      </div>
    </header> -->

    <!-- <header>
      <div
        class="plant-nav-item"
        :class="plantNavIndex === item.id ? 'active' : ''"
        v-for="item in plantNav"
        :key="item.id"
        @click="plantNavIndex = item.id"
      >
        <img
          :src="plantNavIndex === item.id ? item.url : item.activeUrl"
          alt=""
        />
        <span>{{ item.name }}</span>
      </div>
    </header> -->
    <main>
      <div class="content">
        <div class="input">
          <input
            style="width: calc(100% - 160px)"
            class="inp"
            v-model="keyword"
            type="text"
            :placeholder="
              plantNavIndex === 1
                ? '请输入厂房名称开始找房'
                : '请输入园区名称开始找房'
            "
          />
          <button class="z-house" @click="toRental">租厂房</button>
          <!-- <button class="m-house" @click="toRental">买厂房</button> -->
        </div>
        <div class="search-box">
          <div class="z-search" style="width: calc(100% - 25px); border: 0">
            <p class="title">
              <span class="title-l">快速筛选</span>
              <span class="title-r" @click="toPush">
                <i class="icon icon-search-map" style="margin-right: 8px"></i>
                地图找房
              </span>
            </p>
            <div class="region">
              <div class="text">
                <div
                  class="text-item"
                  :class="regionIndex === item.id ? 'active' : ''"
                  v-for="item in region"
                  :key="item.id"
                  @click="setRegionIndex(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="area" v-show="plantNavIndex === 1">
              <div class="text">
                <div
                  class="text-item"
                  :class="areaIndex === item.id ? 'active' : ''"
                  v-for="item in area"
                  :key="item.id"
                  @click="setAreaIndex(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="z-search" style="margin-right: 0">
            <p class="title">
              <span class="title-l">买厂房</span>
              <span class="title-r" @click="toPush">
                <i class="icon icon-search-map" style="margin-right: 8px"></i>
                地图找房
              </span>
            </p>
            <div class="region">
              <div class="text">
                <div
                  class="text-item"
                  :class="regionIndex === item.id ? 'active' : ''"
                  v-for="item in region"
                  :key="item.id"
                  @click="setRegionIndex(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="area">
              <div class="text">
                <div
                  class="text-item"
                  :class="areaIndex === item.id ? 'active' : ''"
                  v-for="item in area"
                  :key="item.id"
                  @click="setAreaIndex(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="code">
        <div class="img">
          <img src="../../assets/xcx-code.png" alt="" />
        </div>

        <div class="code-text">
          <p>扫码快速找房</p>
          <!-- <p>金牌经纪人帮你找</p> -->
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { homeSearchLabel } from 'services/factory';
export default {
  name: 'Search',
  data () {
    return {
      nav: [
        { id: 1, url: require('../../assets/home/厂房.png'), activeUrl: require('../../assets/home/厂房-选中.png'), name: '厂房' },
        { id: 2, url: require('../../assets/home/写字楼.png'), activeUrl: require('../../assets/home/写字楼-选中.png'), name: '写字楼' },
        { id: 3, url: require('../../assets/home/土地.png'), activeUrl: require('../../assets/home/土地-选中.png'), name: '土地' },
        { id: 4, url: require('../../assets/home/商铺.png'), activeUrl: require('../../assets/home/商铺-选中.png'), name: '商铺' },
        { id: 5, url: require('../../assets/home/仓房.png'), activeUrl: require('../../assets/home/仓房-选中.png'), name: '仓库' },
      ],
      plantNav: [
        { id: 1, url: require('../../assets/home/厂房.png'), activeUrl: require('../../assets/home/厂房-选中.png'), name: '租厂房' },
        // { id: 2, url: require('../../assets/home/写字楼.png'), activeUrl: require('../../assets/home/写字楼-选中.png'), name: '找园区' },
      ],
      navIndex: 1,
      plantNavIndex: 1,
      keyword: "",
      activeIndex: '1',
      regionIndex: null,
      areaIndex: null,
      region: [],
      area: [],
    }
  },
  mounted () {
    this.$bus.$on('changeCity', item => {
      homeSearchLabel({ cityId: item.cityId }).then(res => {
        if (res.data.status === 200) {
          this.region = res.data.data.filter(i => {
            if (i.typeName === '区域') return i.searchParams
          })[0].searchParams
          this.area = res.data.data.filter(i => {
            if (i.typeName === '面积') return i.searchParams
          })[0].searchParams
        }
      })
    })
  },
  methods: {
    setActiveIndex (index) {
      this.activeIndex = index;
    },
    setRegionIndex (item) {
      this.regionIndex = item.id
      // 租厂房
      if (this.plantNavIndex === 1) {
        this.$router.push({
          path: `/plantRental/cs/kw/dr${item.id}/al/s`,
          // params: {
          //   districtId: item.id
          // }
        })
      }
      // 找园区
      if (this.plantNavIndex === 2) {
        this.$router.push({
          path: `/searchGardenArea/kw/dr${item.id}`,
          // params: {
          //   districtId: item.id
          // }
        })
      }

    },
    setAreaIndex (item) {
      this.areaIndex = item.id
      // 租厂房
      if (this.plantNavIndex === 1) {
        this.$router.push({
          path: `/plantRental/cs/kw/dr/al${item.id}/s`,
          // params: {
          //   areaLabelIds: item.id
          // }
        })
      }
    },
    toRental () {
      // 租厂房
      if (this.plantNavIndex === 1) {
        this.$router.push({
          path: `/plantRental/cs/kw${this.keyword}/dr/al/s`,
          // params: {
          //   keyword: this.keyword
          // }
        })
      }
      // 找园区
      if (this.plantNavIndex === 2) {
        this.$router.push({
          path: `/searchGardenArea/kw${this.keyword}/dr`,
          // params: {
          //   keyword: this.keyword
          // }
        })
      }
    },
    toPush () {
      // this.$router.push({
      //   name: 'mapSearch'
      // })
      let routeUrl = this.$router.resolve({
        path: '/mapSearch',
      })
      window.open(routeUrl.href, '_blank');
    }
  }
};
</script>

<style lang="less" scoped>
.search {
  margin: 0 calc(50% - 600px);
  min-height: 260px;
  margin-top: -126px;
  margin-bottom: 36px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  header {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    .nav-item {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      span {
        font-size: 20px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
      &:nth-child(1) {
        border-bottom: 1px solid rgba(255, 134, 41, 1);
      }
      &:nth-child(2) {
        border-bottom: 1px solid rgba(69, 143, 255, 1);
      }
      &:nth-child(3) {
        border-bottom: 1px solid rgba(10, 204, 136, 1);
      }
      &:nth-child(4) {
        border-bottom: 1px solid rgba(255, 186, 48, 1);
      }
      &:nth-child(5) {
        border-bottom: 1px solid rgba(128, 149, 255, 1);
      }
      &.active {
        background: rgba(255, 134, 41, 1);
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(255, 134, 41, 1);
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: calc(50% - 12px);
          display: block;
          border-width: 12px;
          border-style: dashed solid dashed dashed; //四个参数分别代表向下，左，上右的小三角(对应方向的小三角设置为solid，其他的为dashed。我这里演示向左的小三角，所以设置第二个）
          border-color: transparent transparent white transparent;
        }
        img {
          filter: grayscale(100%) brightness(200%);
        }
        span {
          color: #fff;
        }
      }
    }
    .plant-nav-item {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      img {
        width: 26px;
        height: 26px;
        margin-right: 10px;
        // filter: grayscale(0%) brightness(0%);
      }
      span {
        font-size: 20px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
      &.active {
        background: linear-gradient(
          180deg,
          rgba(255, 134, 41, 0.16) 0%,
          rgba(255, 128, 31, 0) 100%
        );
        position: relative;
        img {
          // filter: grayscale(0%) brightness(100%);
        }
        span {
          color: rgba(236, 102, 0, 1);
        }
      }
    }
  }
  main {
    padding: 24px;
    padding-right: 30px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    // align-items: center;
    .content {
      width: calc(100% - 210px);
      .input {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .inp {
          width: calc(100% - 280px);
          height: 46px;
          opacity: 1;
          border-radius: 4px;
          background: rgba(245, 245, 245, 1);
          padding: 0 20px;
          border: 1px solid rgba(238, 238, 238, 1);
        }
        .z-house {
          width: 120px;
          height: 46px;
          opacity: 1;
          border-radius: 4px;
          background: rgba(255, 134, 41, 1);
          font-size: 16px;
          color: rgba(255, 255, 255, 1);
          margin: 0 16px;
        }
        .m-house {
          width: 120px;
          height: 46px;
          opacity: 1;
          border-radius: 4px;
          background: rgba(69, 143, 255, 1);
          font-size: 16px;
          color: rgba(255, 255, 255, 1);
        }
      }
      .search-box {
        width: 100%;
        display: flex;
        align-items: center;
        .z-search {
          width: calc(50% - 25px);
          &:nth-child(1) {
            // padding-right: 25px;
            border-right: 1px solid #eee;
          }
          &:nth-child(2) {
            padding-left: 25px;
          }
          p.title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            .title-l {
              font-size: 18px;
              color: rgba(0, 0, 0, 1);
            }
            .title-r {
              display: flex;
              align-items: center;
              font-size: 16px;
              color: rgba(0, 102, 255, 1);
              cursor: pointer;
            }
          }
        }
      }
    }
    .code {
      width: 170px;
      height: 190px;
      margin-left: 30px;
      opacity: 1;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(255, 134, 41, 1);
      overflow: hidden;
      .img {
        width: 100%;
        height: 145px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          // width: 132px;
          height: 124px;
        }
      }
      .code-text {
        background: rgba(255, 134, 41, 1);
        height: 44px;
        padding: 8px 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 23.17px;
          color: rgba(255, 255, 255, 1);
          text-align: center;
        }
      }
    }
  }

  // ----------------------------------------------------------------------------
  .region,
  .area {
    display: flex;
    width: 100%;
    .text {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &-item {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(85, 85, 85, 1);
        margin-right: 25px;
        margin-bottom: 12px;
        padding: 2px 4px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .active {
    background: rgba(236, 102, 0, 1);
    color: #fff !important;
  }
}
</style>