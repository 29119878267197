<template>
  <div class="home" ref="home">
    <el-header
      id="banner"
      class="banner"
      :style="{ background: 'url(' + img + ')' }"
    >
      <div class="banner-header" :class="rollVisible ? 'roll-header' : ''">
        <div class="banner-header-left">
          <img src="../../assets/plant/details/logo.png" alt="亿楼" />
          <Location ref="location_float" styleType="black" />
          <div class="nav">
            <div
              class="nav-item"
              :class="navIndex === item.id ? 'active' : ''"
              v-for="item in nav"
              :key="item.id"
              @click="toPushNav(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="banner-header-right">
          <div class="login">
            <div class="user">
              <i
                style="width: 24px; height: 24px"
                v-if="!userInfo.phone || !userInfo.headImg"
                class="icon icon-user"
              ></i>
              <img
                v-else
                :src="los + userInfo.headImg + '?' + Date.now()"
                alt=""
                class="tx-img"
                @click="toPush(info[0])"
              />
              <span
                v-if="userInfo.phone"
                @click="toPush(info[0])"
                style="cursor: pointer"
                >{{ userInfo.phone | phoneFilter }}</span
              >
            </div>
            <div class="login-btn" @click="loginORquit">
              {{ userInfo.phone ? '退出' : '登录' }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="banner-main"
        :style="{ paddingTop: rollVisible ? '280px' : '' }"
      >
        <!-- <div class="title">一站式厂房选址平台</div> -->
      </div>
    </el-header>

    <Search />
    <!-- 厂房出租 -->
    <el-container class="plant-rental">
      <div class="plant-rental-title">
        <div class="plant-rental-title-left">
          <span class="title1">厂房出租</span>
          <span class="title2">为你推荐</span>
        </div>
        <!-- <div class="plant-rental-title-right">查看更多</div> -->
      </div>
      <div class="plant-rental-main">
        <div
          class="plant-rental-main-item"
          v-for="item in plantList"
          :key="item.id"
          @click="toPlantDetails(item)"
        >
          <div class="img">
            <SpecialOffer
              v-show="item.preferential"
              :label="item.preferential"
            />
            <el-image
              class="img"
              v-if="!item.coverImg"
              :src="require('../../assets/no-img-home.png')"
              lazy
            >
              <div slot="error" class="image-slot">
                <img
                  style="width: 100%"
                  :src="'../../assets/no-img-home.png' + '?' + Date.now()"
                  alt=""
                />
              </div>
            </el-image>
            <el-image
              class="img"
              v-else
              :src="los + item.coverImg + '?' + Date.now()"
              lazy
            ></el-image>
            <!-- <div class="label vr" @click.stop="toVR">
              <i class="icon icon-VR"></i>
              <span>VR</span>
            </div> -->
            <!-- <div class="label" v-else>
              <i class="icon icon-video"></i>
            </div> -->
          </div>
          <div class="item-content">
            <p class="item-content-title" :title="item.title">
              {{ item.title }}
            </p>
            <div class="item-content-main">
              <div class="label">
                <div class="label-line">
                  <span>{{ item.floorTypeName }}</span>
                  <div class="line"></div>
                  <span>{{ item.buildingTypeName }}</span>
                  <div class="line"></div>
                  <span>{{ item.structureTypeName }}</span>
                </div>
                <div class="label-line">
                  <!-- <i class="icon icon-plant-details-location"></i> -->

                  <span style="color: rgba(51, 51, 51, 1)"
                    >{{
                      item.totalArea !== item.minArea
                        ? `${item.minArea}-${item.totalArea}`
                        : item.totalArea
                    }}㎡</span
                  >
                  <div class="line"></div>
                  {{ item.district }}-{{ item.street }}
                </div>
              </div>
            </div>
            <div
              class="count"
              :title="`${item.price}元/㎡/月${
                item.price !== item.maxPrice ? '起' : ''
              }`"
            >
              {{ item.price
              }}<span class="price-unit"
                >元/㎡/月{{ item.price !== item.maxPrice ? '起' : '' }}</span
              >
            </div>
            <!-- <div class="item-content-label">
              <div
                class="item-content-label-item"
                v-for="(i, index) in labelFilter(item.labels)"
                :key="index"
                :title="i"
              >
                {{ i }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </el-container>
    <Footer />
    <!-- 侧边悬浮 -->
    <Sidebar />
    <!-- 活动 -->
    <!-- <Activity /> -->
    <!-- 退出登录弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="400px">
      <div class="body" style="text-align: center">是否确认退出登录</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toLogout">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
import { letRecommend } from 'services/factory'
import { logout } from 'services/login';
import Location from 'components/home/location.vue';
import Search from 'components/home/search.vue';
import Sidebar from 'components/home/sidebar.vue';
// import Activity from 'components/home/activity.vue';
import Footer from 'components/home/footer.vue';
import SpecialOffer from 'components/other/SpecialOffer.vue';
const los = window.los
import img from '../../assets/home/banner.png'
export default {
  name: 'Home',
  components: {
    Location,
    Search,
    Sidebar,
    // Activity,
    Footer,
    SpecialOffer
  },
  data () {
    return {
      ak: '26z9D0KsGMqPXjNW8vVV3Kfa4zkS2BDg',
      city: '',
      los,
      img,
      rollVisible: false,
      dialogVisible: false,
      nav: [
        { id: 1, url: '', name: '写字楼' },
        { id: 2, url: '/plant', name: '厂房' },
        // { id: 3, url: '', name: '仓库' },
        // { id: 4, url: '', name: '商铺' },
        // { id: 5, url: '', name: '土地' },
        // { id: 6, url: '/my', name: '关于我们' },
      ],
      navIndex: 2,
      info: [
        { id: 1, text: '个人中心', url: '/userHome', },
        { id: 2, text: '关注房源', url: '', },
        { id: 3, text: '看房记录', url: '', },
      ],
      plantList: [],
      username: '',
      userInfo: {}
    }
  },
  mounted () {
    // 判断token是否失效
    this.$bus.$on('isLoginType', status => {
      if (!status) {
        if (!sessionStorage.ticket)
          var timer = setTimeout(() => {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash;
            clearTimeout(timer)
          }, 500)
      }
    })
    // 获取用户信息
    if (localStorage.userInfo) this.userInfo = JSON.parse(localStorage.userInfo)
    this.$bus.$on('getUserInfo', userInfo => {

      this.userInfo = userInfo
    })
    // this.initMap()
    let vm = this
    this.$bus.$on('changeCity', item => {
      console.log(item);
      vm.getLetRecommend(item.cityId)
    })
    let banner = document.getElementById('banner')
    window.addEventListener('scroll', () => {
      let { top } = banner.getBoundingClientRect()
      if (top < -80) {
        vm.rollVisible = true
      } else {
        vm.rollVisible = false
      }
    })
    let location_float = vm.$refs.location_float.$el
    window.addEventListener("click", function (e) {
      var event = e || window.event;
      var target = event.target || event.srcElement;
      if (!location_float.contains(target) && vm.$refs.location_float) vm.$refs.location_float.show = false
    })
  },
  methods: {
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    // 初始化地图
    initMap () {
      // 传入密钥获取地图回调。
      let vm = this
      // BMPGL(this.ak).then((BMapGL) => {
      this.getMap().then(LocalCity => {
        console.log('BMapGL---------', BMapGL, LocalCity);
        let myCity = new LocalCity();
        console.log('myCity---------', myCity.get);
        try {
          myCity.get(async (result) => {
            console.log('result---------', result);
            if (result.name.indexOf('市' !== -1))
              vm.city = result.name.replace("市", "")
          })
        } catch (error) {
          console.log("error", error);
        }

      })
    },
    getMap () {
      return new Promise(resolve => {
        if (window.BMapGL && window.BMapGL.LocalCity) {
          let LocalCity = window.BMapGL.LocalCity;
          resolve(LocalCity)
        } else {
          let timer = setInterval(() => {
            if (window.BMapGL && window.BMapGL.LocalCity) {
              let LocalCity = window.BMapGL.LocalCity;
              resolve(LocalCity)
              clearInterval(timer)
            }
          })
        }
      })
    },
    // 退出-登录
    loginORquit () {
      if (this.userInfo.phone) {
        this.dialogVisible = true
      } else {
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
        }, 500)
      }
    },
    toLogout () {
      logout().then(res => {
        if (res.data.status === 200) {
          this.userInfo = {}
          localStorage.removeItem('userInfo')
          localStorage.removeItem('token')
          this.dialogVisible = false
          return this.$message({ message: res.data.msg, type: 'success' });
        } else {
          return this.$message.error('退出登录失败，请再次点击')
        }
      })
    },
    toPlantDetails (item) {
      let routeUrl = this.$router.resolve({
        path: '/plantDetails',
        data: {
          item: JSON.stringify(item)
        }
      })
      window.open(routeUrl.href + "/" + item.id, '_blank');
    },
    toVR () {
      let routeUrl = this.$router.resolve({
        path: '/vr',
      })
      window.open(routeUrl.href, '_blank');
    },
    toPushNav (item) {
      if (item.id === 2) {
        this.$router.push({
          path: item.url
        })
      }

      if (item.id === 1) {
        window.open(`${window.officeUrl}office`, '_blank');
      }
    },
    toPush (item) {
      let routeUrl = this.$router.resolve({
        path: item.url
      })
      window.open(routeUrl.href, '_blank');
    },
    // 获取推荐房源
    getLetRecommend (cityId) {
      letRecommend({ cityId }).then(res => {
        if (res.data.status === 200) {
          this.plantList = res.data.data.map(item => {
            return {
              ...item,
              coverImg: item.coverImg ? item.coverImg + '?' + Date.now() : ''
            }
          })
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  // height: auto !important;
}
.roll-header {
  position: fixed;
  padding: 0 calc(50% - 600px);
  left: 0;
  width: 100%;
  height: 80px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.banner {
  height: 640px !important;
  opacity: 1;
  padding: 0 calc(50% - 600px);
  // background: url(../../assets/home/banner.png);
  background-size: 100% 100% !important;
  margin-bottom: 36px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin-bottom: 200px;
    &-left {
      display: flex;
      align-items: center;
      img {
        width: 114px;
        height: 34px;
        margin-right: 17px;
      }
      .icon {
        margin-right: 5px;
      }

      .nav {
        display: flex;
        align-items: center;
        margin-left: 36px;
        &-item {
          margin-left: 28px;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 18px;
          color: rgba(51, 51, 51, 1);
          cursor: pointer;
          &.active {
            color: rgba(236, 102, 0, 1);
          }
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
      .login {
        display: flex;
        align-items: center;
        justify-content: end;
        // margin-bottom: 18px;
        position: relative;
        .user {
          margin-right: 8px;
          display: flex;
          align-items: center;
          i {
            width: 20px;
            height: 20px;
          }
          .tx-img {
            width: 24px;
            height: 24px;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            color: rgba(51, 51, 51, 1);
          }
        }
        .icon {
          margin-right: 8px;
        }
        &-btn {
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          color: rgba(236, 102, 0, 1);
          cursor: pointer;
        }
        &:hover {
          .userinfo {
            display: block;
          }
        }
        .userinfo {
          position: absolute;
          top: 20px;
          background-color: #fff;
          display: none;
          border-radius: 4px;
          .info-item {
            width: 80px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
    }
    .loaction-city {
      display: flex;
      align-items: center;
      height: 24px;
      line-height: 24px;
      padding: 0 9px;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.24);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 14px;
      color: rgba(255, 255, 255, 1);
    }
  }
  &-main {
    position: relative;
    .title {
      font-size: 50px;
      font-weight: 400;
      line-height: 74px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      margin-bottom: 200px;
    }
  }
}
.plant-rental {
  margin: 0 calc(50% - 600px);
  display: block;
  &-title {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      .title1 {
        font-size: 26px;
        font-weight: 500;
        line-height: 38px;
        color: rgba(51, 51, 51, 1);
        margin-right: 12px;
      }
      .title2 {
        font-size: 26px;
        font-weight: 500;
        line-height: 38px;
        color: rgba(153, 153, 153, 1);
      }
    }
    &-right {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(102, 102, 102, 1);
      cursor: pointer;
    }
  }
  &-main {
    margin-bottom: 17px;
    display: flex;
    flex-wrap: wrap;
    &-item {
      width: calc(33.333333% - 20px);
      height: 426px;
      border-radius: 4px;
      border: 1px solid rgba(238, 238, 238, 1);
      margin-bottom: 40px;
      cursor: pointer;
      &:nth-child(3n-1) {
        margin: 0 30px;
      }
      &:hover {
        .item-content-title {
          text-decoration: underline;
        }
      }
      .img {
        width: 100%;
        height: 285px;
        position: relative;
        border-radius: 4px 4px 0px 0px;
        overflow: hidden;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 0px 0px;
        }
        .label {
          position: absolute;
          top: 12px;
          left: 12px;
          width: 32px;
          height: 32px;
          opacity: 1;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .vr {
          width: 68px;
          height: 32px;
          line-height: 32px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(224, 224, 224, 1);
          span {
            margin-left: 4px;
            font-weight: 100;
          }
        }
      }
      .item-content {
        width: 100%;
        height: calc(100% - 285px);
        padding: 12px 20px 20px;
        box-sizing: border-box;
        &-title {
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
          color: rgba(51, 51, 51, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-main {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 6px;
          margin-bottom: 8px;
          .label {
            &-line {
              display: flex;
              align-items: center;
              // justify-content: center;
              font-size: 15px;
              font-weight: 400;
              line-height: 18px;
              color: rgba(153, 153, 153, 1);
              &:first-child {
                margin-bottom: 6px;
              }
              .line {
                width: 1px;
                height: 12px;
                background-color: rgba(221, 221, 221, 1);
                margin: 0 10px;
              }
            }
          }
        }

        .count {
          font-size: 24px;
          font-weight: 400;
          line-height: 30px;
          font-family: DIN;
          color: rgba(236, 102, 0, 1);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .price-unit {
            font-size: 16px;
          }
        }
        &-label {
          display: flex;
          align-items: center;
          &-item {
            padding: 3px 10px;
            border-radius: 2px;
            background: rgba(252, 244, 230, 1);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: rgba(247, 162, 25, 1);
            margin-right: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    display: flex;
    align-items: center;
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
</style>
