<template>
  <div class="details-header-info">
    <div class="count-label">
      <div class="code">
        <div class="">
          <div class="count">
            <span class="num">
              {{
                details.price !== details.maxPrice
                  ? `${details.price}-${details.maxPrice}`
                  : details.price
              }}<span class="price-unit f-s-20">元/㎡/月</span>
            </span>
            <span
              class="report"
              v-show="!$route.query.broker && !$route.query.admin"
              @click="openReport"
            >
              <i class="icon icon-plant-details-report"></i>
              举报房源
            </span>
          </div>
          <div class="label">
            <div
              class="label-item"
              v-for="(i, index) in labelFilter(details.labels)"
              :key="index"
              :title="i"
            >
              {{ i }}
            </div>
          </div>
        </div>
        <!-- <img src="../../assets/xcx-code.png" alt="" /> -->
      </div>

      <div class="label-info">
        <div class="">
          <div class="text1">{{ details.totalArea || '-' }}m²</div>
          <div class="text2">总面积</div>
        </div>
        <div class="">
          <div class="text1">{{ details.buildingTypeName || '-' }}</div>
          <div class="text2">厂房类型</div>
        </div>
        <div class="">
          <div class="text1">{{ details.structureTypeName || '-' }}</div>
          <div class="text2">厂房结构</div>
        </div>
        <div class="">
          <div class="text1">{{ details.minArea || '-' }}m²</div>
          <div class="text2">起租面积</div>
        </div>
      </div>
    </div>
    <div class="info">
      <p>
        <label for="">房源编号：</label>
        <span>{{ details.buildingNo }}</span>
      </p>
      <p>
        <label for="">新旧程度：</label>
        <span>{{ details.yearType || '-' }}</span>
      </p>
      <p>
        <label for="">区域：</label>
        <span>{{ details.district }} - {{ details.street }}</span>
      </p>
      <p>
        <label for="">楼层：</label>
        <span>{{ details.floorTypeName || '-' }}</span>
      </p>
      <p>
        <label for="">出租方式：</label>
        <span>{{ details.sublet ? '分租' : '整租' }}</span>
      </p>
      <!-- <p>
        <label for="">所属园区：</label>
        <span class="underline" @click="toGardenDetails">{{
          details.sublet ? '分租' : '整租'
        }}</span>
      </p> -->
    </div>
    <div class="user" v-if="!$route.query.broker && !$route.query.admin">
      <!-- <div class="tx" @click="toPush('/counselorHome')"></div> -->
      <img
        class="tx"
        :src="los + user.headImg + '?' + Date.now()"
        alt=""
        @click="toPush('/counselorHome')"
      />
      <div class="user-info" @click="toPush('/counselorHome')">
        <div class="user-info-name">
          <span>{{ user.name }}</span>
          <!-- <el-rate v-model="value1"></el-rate> -->
        </div>
        <div class="user-info-company">{{ user.company }}</div>
      </div>
      <div class="wx-code">
        <div class="qrCodeDiv" ref="qrCodeDiv"></div>
        <p>微信扫码拨号</p>
      </div>
    </div>
    <div class="user" v-else>
      <!-- <div class="tx" @click="toPush('/counselorHome')"></div> -->
      <img class="tx" :src="los + user.headImg + '?' + Date.now()" alt="" />
      <div class="user-info">
        <div class="user-info-name">
          <span>{{ user.name }}</span>
          <!-- <el-rate v-model="value1"></el-rate> -->
        </div>
        <div class="user-info-company">{{ user.company }}</div>
      </div>
      <div class="wx-code">
        <div class="qrCodeDiv" ref="qrCodeDiv"></div>
        <p>微信扫码拨号</p>
      </div>
    </div>
    <div class="phone flex-align-center">
      <i class="icon icon-plant-details-phone"></i>
      <span>{{ user.secretPhoneText || '暂无号码' }}</span>
    </div>
    <!-- 举报弹窗 -->
    <el-dialog
      title="举报房源"
      :visible.sync="dialogVisible"
      width="560px"
      :before-close="handleClose"
    >
      <div class="body">
        <p>一. 请选择举报原因 <span class="requir-field">*</span></p>
        <el-radio-group class="radio-group" v-model="reason">
          <el-radio class="radio-item" label="虚假房源">虚假房源</el-radio>
          <el-radio class="radio-item" label="价格虚标">价格虚标</el-radio>
          <el-radio class="radio-item" label="套取联系方式"
            >套取联系方式</el-radio
          >
          <el-radio class="radio-item" label="无法联系">无法联系</el-radio>
          <el-radio class="radio-item" label="无法出租房源"
            >无法出租房源</el-radio
          >
        </el-radio-group>
        <p>二. 详细说明</p>
        <el-input
          class="textarea"
          type="textarea"
          :rows="2"
          placeholder="您可提供更详细的说明！"
          v-model="detail"
        >
        </el-input>
        <p>三. 图片证据</p>
        <el-upload
          class="upload-demo"
          drag
          :action="uploadIp + '/file/common/upload/img/1'"
          :before-upload="beforeUpload"
          :on-change="handleCertifiedPreview"
          :on-remove="handleCertifiedRemove"
          multiple
        >
          <div class="plus">
            <i class="el-icon-plus"></i>
          </div>
          <div class="el-upload__text">支持JPG，PNG</div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>
const uploadIp = window.uploadIp
const los = window.los
import logo1 from '../../assets/logo1.png'
import { reportBuilding } from 'services/factory'
import { getOpenUser } from 'services/broker'
import { isLogin } from 'services/login';
import QRCode from 'qrcodejs2';//引入生成二维码插件
export default {
  name: 'DetailsHeaderInfo',
  data () {
    return {
      uploadIp,
      los,
      value1: null,
      dialogVisible: false,
      detail: '',
      reason: '虚假房源',
      type: 1,
      image: [],
      user: {}
    }
  },
  props: {
    details: Object,
    broker: Object
  },
  watch: {
    broker (val) {
      this.user = val
      this.openCode(`${window.phoneCall}${this.user.secretPhone}${!this.user.secretPhoneExpiration ? '' : ',,' + this.user.secretPhoneExpiration}`)
    }
  },
  mounted () {
    console.log('details', this.details);
    // if (this.details.broker) {
    //   this.user = this.details.broker
    //   this.openCode(`${window.phoneCall}${this.user.secretPhone}/${this.user.secretPhoneExpiration}`)
    // } else {
    //   this.getUser()
    // }
  },
  methods: {
    openReport () {
      isLogin().then(res => {
        if (res.data.status === 200) {
          if (res.data.data) {
            this.dialogVisible = true
          } else {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
          }
        }
      })
    },
    getUser () {
      let params = {
        brokerId: this.details.brokerId
      };
      getOpenUser(params).then(res => {
        if (res.data.status === 200) {
          this.user = res.data.data
          let text = ''
          if (res.data.data.secretPhoneExpiration) {
            if (res.data.data.secretPhoneExpiration === res.data.data.secretPhone) {
              text = res.data.data.secretPhone
            } else {
              text = `${res.data.data.secretPhone},,${res.data.data.secretPhoneExpiration}`
            }
          } else {
            text = res.data.data.secretPhone
          }
          this.openCode(`${window.phoneCall}${text}`)
        }
      })
    },
    openCode (text) {
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
        let qrcode = new QRCode(this.$refs.qrCodeDiv, {
          text,//二维码链接，参数是否添加看需求
          width: 70,//二维码宽度
          height: 70,//二维码高度
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
        });
        let logo = new Image();
        logo.crossOrigin = 'Anonymous';
        logo.src = logo1
        let con = this.$refs.qrCodeDiv;
        logo.onload = function () {
          let qrImg = qrcode._el.getElementsByTagName('img')[0];
          let canvas = qrcode._el.getElementsByTagName('canvas')[0];
          var ctx = canvas.getContext("2d");
          ctx.drawImage(logo, 70 * 0.5 - 11, 70 * 0.5 - 11, 22, 22);
          qrImg.src = canvas.toDataURL();
          con.appendChild(qrImg);
        }
      })
    },
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    handleClose () {
      this.dialogVisible = false
    },
    // 园区详情
    toGardenDetails () {
      let routeUrl = this.$router.resolve({
        path: '/gardenDetails',
      })
      window.open(routeUrl.href + "/" + this.details.id, '_blank');
    },
    toPush (path) {
      this.$router.push({
        path: path + '/' + this.details.id,
      })
    },
    beforeUpload (file) {
      let imgSize = Number(file.size / 1024 / 1024);
      console.log(imgSize);
      if (imgSize > 5) {
        this.$message({
          message: '文件不超过5MB',
          type: 'warning'
        });
        return false
      } else {
        return true
      }
    },
    handleCertifiedRemove (file, fileList) {
      this.image = fileList.filter(item => item?.response.status === 200).map(item => item.response.data.fileUrl);
    },
    handleCertifiedPreview (file, fileList) {
      this.image = fileList.filter(item => item.status === 'success').filter(item => item?.response.status === 200).map(item => item.response.data.fileUrl)
      console.log(this.image)
    },
    submit () {
      let params = {
        buildingId: this.details.id,
        reason: this.reason,
        detail: this.detail,
        type: this.type,
        image: this.image
      }
      reportBuilding(params).then(res => {
        if (res.data.status === 200) {
          this.dialogVisible = false
          this.reason = '虚假房源'
          this.detail = ''
          this.image = []
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
        }
      })
    }
  }
};
  </script>
  
  <style lang="less" scoped>
.details-header-info {
  width: 100%;
  height: 100%;
  .count-label {
    border-bottom: 1px solid #f2f2f2;
    .code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .count {
      height: 85px;
      // display: flex;
      align-items: center;
      justify-content: space-between;
      .num {
        font-size: 32px;
        font-weight: 600;
        line-height: 85px;
        color: rgba(236, 102, 0, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 30px;
      }
      .report {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        i {
          margin-right: 4px;
          width: 14px;
          height: 14px;
        }
      }
    }
    .label {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 26px;
      &-item {
        padding: 3px 10px;
        border-radius: 2px;
        background: #f6f5f4;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
        margin-right: 12px;
      }
    }
    .label-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .text1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        color: rgba(51, 51, 51, 1);
        text-align: center;
      }
      .text2 {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(136, 136, 136, 1);
        text-align: center;
      }
    }
  }
  .info {
    padding: 12px 0 18px;
    border-bottom: 1px solid #f2f2f2;
    p {
      padding: 8px 0;
      label {
        font-size: 16px;
        line-height: 22px;
        color: rgba(136, 136, 136, 1);
        width: 80px;
        display: inline-block;
      }
      span {
        font-size: 16px;
        line-height: 22px;
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  .user {
    height: 127px;
    display: flex;
    align-items: center;
    .tx {
      width: 78px;
      height: 78px;
      border-radius: 50%;
      border: 1px solid #eeeeee;
      margin-right: 28px;
      cursor: pointer;
    }
    .user-info {
      width: calc(100% - 248px);
      margin-right: 52px;
      cursor: pointer;
      &-name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 600;
        line-height: 38px;
        color: rgba(0, 0, 0, 1);
        span {
          margin-right: 40px;
        }
        /deep/ .el-rate {
          display: flex;
          align-items: center;
        }
      }
      &-company {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: rgba(136, 136, 136, 1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .wx-code {
      .qrCodeDiv {
        width: 70px;
        height: 70px;
        margin-bottom: 4px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      img {
        width: 70px;
        height: 70px;
      }
      p {
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 12.06px;
        color: rgba(136, 136, 136, 1);
      }
    }
  }
  .phone {
    height: 60px;
    line-height: 60px;
    text-align: center;
    justify-content: center;
    background: #ec6600;
    i {
      margin-right: 20px;
      width: 24px;
      height: 24px;
    }
    span {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      color: rgba(255, 255, 255, 1);
    }
  }
}

// 弹窗
.body {
  p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 8px;
    .requir-field {
      margin-left: 4px;
      color: rgba(212, 48, 48, 1);
    }
  }
  .radio-group {
    width: 100%;
    padding-left: 20px;
    box-sizing: border-box;
    .radio-item {
      width: 33%;
      margin-bottom: 4px;
      margin-right: 0;
      /deep/.el-radio__label {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(51, 51, 51, 1);
      }
      /deep/ .el-radio__inner {
        border: 1px solid rgba(236, 102, 0, 1);
        background: rgba(255, 255, 255, 1);
      }
      /deep/ .el-radio__input.is-checked .el-radio__inner::after {
        background-color: rgba(236, 102, 0, 1);
        width: 5px;
        height: 5px;
      }
    }
  }
  .textarea {
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    /deep/ .el-textarea__inner {
      height: 70px;
      padding: 12px 16px;
      box-sizing: border-box;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);
      border: 0;
    }
  }
  .upload-demo {
    padding-left: 20px;
    box-sizing: border-box;
    .plus {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
      border-radius: 13px;
      margin: 10px auto;
      i {
        font-size: 16px;
        margin: 0;
      }
    }
    .el-upload__text {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: rgba(153, 153, 153, 1);
    }
    /deep/ .el-upload-dragger {
      width: 124px;
      height: 76px;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);

      border: 1px solid rgba(247, 247, 247, 1);
    }
  }
}
.dialog-footer {
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}

/deep/ .el-icon-star-on {
  color: #ec6600 !important;
}
/deep/ .el-icon-star-off {
  color: #999999 !important;
}

// 弹窗
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 42px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/ .el-button {
    display: flex;
    align-items: center;
  }
}
</style>
  