import { render, staticRenderFns } from "./DialogImg.vue?vue&type=template&id=977faed4&scoped=true&"
import script from "./DialogImg.vue?vue&type=script&lang=js&"
export * from "./DialogImg.vue?vue&type=script&lang=js&"
import style0 from "./DialogImg.vue?vue&type=style&index=0&id=977faed4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "977faed4",
  null
  
)

export default component.exports