<template>
  <div class="plant-rental">
    <header>精准找房</header>
    <van-field
      class="search"
      v-model="keyword"
      center
      clearable
      placeholder="请输入房源名称进行搜索"
    >
      <template #button>
        <van-button
          class="search-btn"
          size="small"
          type="primary"
          @click="search"
          >搜索</van-button
        >
      </template>
    </van-field>
    <div class="more-search">
      <van-dropdown-menu ref="menu">
        <van-dropdown-item title="区域" ref="item1">
          <div class="more-search-content">
            <van-tree-select
              class="tree-select"
              :items="place"
              :active-id.sync="streetId"
              @click-nav="getStreet"
              :main-active-index.sync="districtIndex"
            />
          </div>
          <div class="more-search-btn" style="padding: 6px 20px">
            <van-button
              class="reset"
              type="default"
              block
              round
              @click="(districtId = ''), (streetId = ''), (districtIndex = 0)"
            >
              重置
            </van-button>
            <van-button
              class="determine"
              type="danger"
              block
              round
              @click="dmPlace"
            >
              确定
            </van-button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="价格" ref="item2">
          <div class="more-search-content">
            <div class="content">
              <div
                class="content-item"
                :class="noLimitPrice ? 'active' : ''"
                @click="nolimitPrice"
              >
                不限
              </div>
              <div
                class="content-item"
                :class="item.state ? 'active' : ''"
                v-for="item in price"
                :key="item.id"
                @click="setPrice(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <p class="tit">自定义价格</p>
            <div class="custom">
              <el-input
                class="min"
                type="number"
                v-model="ruleForm.minPrice"
                @input="e => NumberCheck(e, 'minPrice')"
                placeholder="最低单价"
              ></el-input>
              <span class="line">-</span>
              <el-input
                class="max"
                type="number"
                v-model="ruleForm.maxPrice"
                @input="e => NumberCheck(e, 'maxPrice')"
                placeholder="最高单价"
              ></el-input>
            </div>
          </div>
          <div class="more-search-btn" style="padding: 6px 20px">
            <van-button
              class="reset"
              type="default"
              block
              round
              @click="nolimitPrice"
            >
              重置
            </van-button>
            <van-button
              class="determine"
              type="danger"
              block
              round
              @click="getPrice"
            >
              确定
            </van-button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="面积" ref="item3">
          <div class="more-search-content">
            <div class="content">
              <div
                class="content-item"
                :class="noLimitArea ? 'active' : ''"
                @click="nolimitArea"
              >
                不限
              </div>
              <div
                class="content-item"
                :class="item.state ? 'active' : ''"
                v-for="item in area"
                :key="item.id"
                @click="setArea(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <p class="tit">自定义面积</p>
            <div class="custom">
              <el-input
                class="min"
                type="number"
                v-model="ruleForm.minArea"
                @input="e => NumberCheck(e, 'minArea')"
                placeholder="最低面积"
              ></el-input>
              <span class="line">-</span>
              <el-input
                class="max"
                type="number"
                v-model="ruleForm.maxArea"
                @input="e => NumberCheck(e, 'maxArea')"
                placeholder="最高面积"
              ></el-input>
            </div>
          </div>
          <div class="more-search-btn" style="padding: 6px 20px">
            <van-button
              class="reset"
              type="default"
              block
              round
              @click="nolimitArea"
            >
              重置
            </van-button>
            <van-button
              class="determine"
              type="danger"
              block
              round
              @click="getArea"
            >
              确定
            </van-button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="更多" ref="item4">
          <div class="more-search-content" style="height: 50vh">
            <p class="tit">年限</p>
            <div class="content">
              <div
                class="content-item"
                :class="yearIds.length > 0 ? '' : 'active'"
                @click="setMore(null, 'yearIds')"
              >
                不限
              </div>
              <div
                class="content-item"
                :class="item.state ? 'active' : ''"
                v-for="item in ageLimit"
                :key="item.id"
                @click="setMore(item, 'yearIds')"
              >
                {{ item.name }}
              </div>
            </div>
            <p class="tit">楼层</p>
            <div class="content">
              <div
                class="content-item"
                :class="floorIds.length > 0 ? '' : 'active'"
                @click="setMore(null, 'floorIds')"
              >
                不限
              </div>
              <div
                class="content-item"
                :class="item.state ? 'active' : ''"
                v-for="item in floor"
                :key="item.id"
                @click="setMore(item, 'floorIds')"
              >
                {{ item.name }}
              </div>
            </div>
            <p class="tit">类型</p>
            <div class="content">
              <div
                class="content-item"
                :class="buildTypeIds.length > 0 ? '' : 'active'"
                @click="setMore(null, 'buildTypeIds')"
              >
                不限
              </div>
              <div
                class="content-item"
                :class="item.state ? 'active' : ''"
                v-for="item in type"
                :key="item.id"
                @click="setMore(item, 'buildTypeIds')"
              >
                {{ item.name }}
              </div>
            </div>
            <p class="tit">结构</p>
            <div class="content">
              <div
                class="content-item"
                :class="structureIds.length > 0 ? '' : 'active'"
                @click="setMore(null, 'structureIds')"
              >
                不限
              </div>
              <div
                class="content-item"
                :class="item.state ? 'active' : ''"
                v-for="item in structure"
                :key="item.id"
                @click="setMore(item, 'structureIds')"
              >
                {{ item.name }}
              </div>
            </div>
            <p class="tit">设施</p>
            <div class="content">
              <div
                class="content-item"
                :class="facilitiesIds.length > 0 ? '' : 'active'"
                @click="setMore(null, 'facilitiesIds')"
              >
                不限
              </div>
              <div
                class="content-item"
                :class="item.state ? 'active' : ''"
                v-for="item in facility"
                :key="item.id"
                @click="setMore(item, 'facilitiesIds')"
              >
                {{ item.name }}
              </div>
            </div>
            <p class="tit">特色</p>
            <div class="content">
              <div
                class="content-item"
                :class="buildLabelIds.length > 0 ? '' : 'active'"
                @click="setMore(null, 'buildLabelIds')"
              >
                不限
              </div>
              <div
                class="content-item"
                :class="item.state ? 'active' : ''"
                v-for="item in feature"
                :key="item.id"
                @click="setMore(item, 'buildLabelIds')"
              >
                {{ item.name }}
              </div>
            </div>
            <p class="tit">租赁方式</p>
            <div class="content">
              <div
                class="content-item"
                :class="letMethod === item.value ? 'active' : ''"
                v-for="item in leaseOptions"
                :key="item.id"
                @click="letMethod = item.value"
              >
                {{ item.label }}
              </div>
            </div>
            <p class="tit">房屋来源</p>
            <div class="content">
              <div
                class="content-item"
                :class="sourceType === item.value ? 'active' : ''"
                v-for="item in housingSourceOptions"
                :key="item.id"
                @click="sourceType = item.value"
              >
                {{ item.label }}
              </div>
            </div>
            <p class="tit">总层数</p>
            <div class="content">
              <div
                class="content-item"
                :class="totalFloor === item.value ? 'active' : ''"
                v-for="item in numberOfPliesOptions"
                :key="item.id"
                @click="totalFloor = item.value"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="more-search-btn" style="padding: 6px 20px">
            <van-button
              class="reset"
              type="default"
              block
              round
              @click="resetMore"
            >
              重置
            </van-button>
            <van-button
              class="determine"
              type="danger"
              block
              round
              @click="confirmMore"
            >
              确定
            </van-button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="排序" ref="item5">
          <div class="more-search-content">
            <div
              class="list-item"
              :class="sortMethod === item.id ? 'active' : ''"
              v-for="item in tabList"
              :key="item.id"
              @click="sortMethod = item.id"
            >
              {{ item.text }}
            </div>
          </div>
          <div class="more-search-btn" style="padding: 6px 20px">
            <van-button
              class="reset"
              type="default"
              block
              round
              @click="sortMethod = 0"
            >
              重置
            </van-button>
            <van-button
              class="determine"
              type="danger"
              block
              round
              @click="sort"
            >
              确定
            </van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="list-box">
      <van-list
        style="height: 100%"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="list-box-item"
          v-for="item in plantList"
          :key="item.id"
          @click="toPlantDetails(item)"
        >
          <div class="img">
            <el-image
              class="img"
              v-if="!item.coverImg"
              :src="require('../../../assets/no-img-list.png')"
              lazy
            >
              <div slot="error" class="image-slot">
                <img
                  style="width: 100%"
                  :src="'../../assets/no-img-list.png' + '?' + Date.now()"
                  alt=""
                />
              </div>
            </el-image>
            <el-image
              class="img"
              v-else
              :src="los + item.coverImg + '?' + Date.now()"
              lazy
            ></el-image>
          </div>
          <div class="list-content">
            <div class="title">{{ item.title }}</div>
            <div class="info">
              <div class="info-item">
                {{
                  item.totalArea !== item.minArea
                    ? `${item.minArea}-${item.totalArea}`
                    : item.totalArea
                }}㎡
              </div>
              <div class="line"></div>
              <div class="info-item">{{ item.buildingTypeName }}</div>
              <div class="line"></div>
              <div class="info-item">{{ item.structureTypeName }}</div>
            </div>
            <div class="label">
              <div
                class="label-item"
                v-for="(i, index) in labelFilter(item.labels)"
                :key="index"
              >
                {{ i }}
              </div>
            </div>
            <div class="count">
              {{ item.price
              }}<span class="price-unit"
                >元/㎡/月{{ item.price !== item.maxPrice ? '起' : '' }}</span
              >
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
const los = window.los
import { searchLabel, searchPage } from 'services/factory'
import { getAreaByParent, openCityAll } from 'services/common';
export default {
  name: 'MPlantRental',
  components: {
  },
  data () {
    return {
      los,
      finished: false,
      ruleForm: {
        minArea: '',
        maxArea: '',
        minPrice: '',
        maxPrice: '',
      },
      // 筛选选项-start
      place: [],
      districtId: null,
      street: [],
      districtIndex: 0,
      streetId: null,
      area: [],
      noLimitArea: true,
      areaLabelIds: [],
      price: [],
      noLimitPrice: true,
      priceLabelIds: [],
      ageLimit: [],
      yearIds: [],
      floor: [],
      floorIds: [],
      type: [],
      buildTypeIds: [],
      structure: [],
      structureIds: [],
      specialIndustry: [
        { id: 1, text: '化工' },
        { id: 2, text: '生物医药' },
        { id: 3, text: '危险品' },
        { id: 4, text: '电子信息' },
      ],
      specialIndustryArr: [],
      facility: [],
      facilitiesIds: [],
      feature: [],
      buildLabelIds: [],
      letMethod: '',
      sourceType: '',
      totalFloor: '',
      leaseOptions: [{
        value: null,
        label: '不限'
      }, {
        value: 1,
        label: '整租'
      }, {
        value: 2,
        label: '分租'
      }],
      housingSourceOptions: [{
        value: null,
        label: '不限'
      }, {
        value: 1,
        label: '经纪人'
      }, {
        value: 2,
        label: '业主'
      }],
      numberOfPliesOptions: [{
        value: null,
        label: '不限'
      }, {
        value: 1,
        label: '单层'
      }, {
        value: 2,
        label: '多层'
      }],
      drop: false,
      // 筛选选项-end
      headerTabList: [
        { id: 1, text: '租厂房' },
        // { id: 2, text: '买厂房' },
        // { id: 2, text: '找园区' },
        { id: 3, text: '地图找房' },
      ],
      headTabIndex: 1,
      tabList: [
        { id: 0, text: '默认排序' },
        { id: 1, text: '最新发布' },
        { id: 2, text: '面积升序' },
        { id: 3, text: '面积降序' },
        { id: 4, text: '价格升序' },
        { id: 5, text: '价格降序' },
      ],
      sortMethod: 0,
      keyword: '',
      total: 0,
      page: 0,
      loading: false,
      plantList: []
    }
  },
  watch: {
  },
  mounted () {
    window.ontouchstart = function (e) {
      e.preventDefault();
    };
    this.getSearch()
    // 百度词条找房
    // 首页跳转
    console.log(this.$route);
    // 城市
    if (this.$route.params.city !== 'cs') {
      localStorage.city = JSON.stringify({ cityId: this.$route.params.city.split('cs')[1] })
      this.getOpenAllCity(this.$route.params.city.split('cs')[1])
    }
    // 关键字
    if (this.$route.params.keyword) {
      this.keyword = this.$route.params.keyword.split('kw')[1]
    }
    // 区
    if (this.$route.params.districtId) {
      this.districtId = this.$route.params.districtId.split('dr')[1];
    }
    // 面积
    if (this.$route.params.areaLabelIds) {
      this.areaLabelIds = this.$route.params.areaLabelIds.split('al')[1] ? [this.$route.params.areaLabelIds.split('al')[1]] : [];
    }
    // 街道
    if (this.$route.params.streetId) {
      this.streetId = this.$route.params.streetId.split('s')[1];
      history.replaceState(null, null, `${location.pathname}#/plantRental_m/cs${this.$route.params.city.split('cs')[1] || ''}/kw${this.$route.params.keyword.split('kw')[1] || ''}/dr${this.$route.params.districtId.split('dr')[1] || ''}/al/s${this.streetId || ''}`)
    }

    // this.getList()
    // 获取位置
    this.getPlace()

    // 移动切换pc
    let bool = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    if (!bool) {
      // Vue.prototype.$pc = false
      this.$router.push(`/plantRental/cs${this.$route.params.city.split('cs')[1]}/kw${this.keyword || ''}/dr${this.districtId || ''}/al/s${this.streetId || ''}`)
    }


    this.sortMethod = this.tabList[0].id;
  },
  methods: {
    labelFilter (val) {
      if (val?.length > 3) {
        return val.slice(0, 3)
      } else {
        return val
      }
    },
    // 获取所有开通的城市
    getOpenAllCity (cityId) {
      let params = {
        types: 1
      }
      openCityAll(params).then(res => {
        if (res.data.status === 200) {
          res.data.data.forEach(item => {
            if (item.cityId == cityId) {
              localStorage.city = JSON.stringify(item)
            }
          });
        }
      })
    },
    // 获取位置
    getPlace () {
      let parentId = JSON.parse(localStorage.city).cityId
      getAreaByParent({ parentId }).then(res => {
        if (res.data.status === 200) {
          this.place = res.data.data.map(item => {
            return {
              text: item.name,
              ...item,
              children: item.child
            }
          })
          this.place.forEach((item, index) => {
            if (item.id === this.districtId && !item.id) {
              this.districtIndex = index
              getAreaByParent({ parentId: this.districtId }).then(res => {
                if (res.data.status === 200) {
                  this.street = res.data.data.map(item => {
                    return {
                      text: item.name,
                      ...item,
                      children: item.child
                    }
                  })
                  this.place.forEach(item => {
                    if (item.id === this.districtId) item.children = this.street
                  })
                }
              })
            }
          })

          this.place.unshift({
            id: '',
            text: '不限',
            children: []
          })
        }
      })
    },
    // 获取街道
    getStreet (index, str) {
      this.streetId = ''
      let parentId = ''
      if (str) {
        parentId = index
      } else {
        parentId = this.place[index].id
        this.districtId = this.place[index].id
      }
      if (!parentId) return
      getAreaByParent({ parentId }).then(res => {
        if (res.data.status === 200) {
          this.street = res.data.data.map(item => {
            return {
              text: item.name,
              ...item,
              children: item.child
            }
          })
          this.place.forEach(item => {
            if (item.id === parentId) item.children = this.street
          })
        }
      })
    },
    // 获取搜索参数
    getSearch () {
      searchLabel().then(res => {
        if (res.data.status === 200) {
          // 处理数据
          //标签类型 1.面积 2.价格 3.年限 4.楼层 5.建筑类型 6.结构 7.房源特色 8.配套设施
          this.area = res.data.data.filter(item => item.type === 1).map(item => {
            return {
              ...item,
              state: false
            }
          })
          this.price = res.data.data.filter(item => item.type === 2).map(item => {
            return {
              ...item,
              state: false
            }
          })
          this.ageLimit = res.data.data.filter(item => item.type === 3).map(item => {
            return {
              ...item,
              state: false
            }
          })
          this.floor = res.data.data.filter(item => item.type === 4).map(item => {
            return {
              ...item,
              state: false
            }
          })
          this.type = res.data.data.filter(item => item.type === 5).map(item => {
            return {
              ...item,
              state: false
            }
          })
          this.structure = res.data.data.filter(item => item.type === 6).map(item => {
            return {
              ...item,
              state: false
            }
          })
          this.feature = res.data.data.filter(item => item.type === 7).map(item => {
            return {
              ...item,
              state: false
            }
          })
          this.facility = res.data.data.filter(item => item.type === 8).map(item => {
            return {
              ...item,
              state: false
            }
          })
        }
      })
    },
    dmPlace () {
      this.$refs.item1.toggle();
      this.search()
    },
    nolimitPrice () {
      this.price.forEach(item => {
        item.state = false
      })
      this.ruleForm.minPrice = null
      this.ruleForm.maxPrice = null
      this.noLimitPrice = true
    },
    setPrice (item) {
      item.state = !item.state
      this.ruleForm.minPrice = null
      this.ruleForm.maxPrice = null
      this.noLimitPrice = false
    },
    resetPrice () {
      this.priceLabelIds = []
      this.ruleForm.minPrice = null
      this.ruleForm.maxPrice = null
      this.noLimitPrice = true
    },
    getPrice () {
      if (this.ruleForm.maxPrice < this.ruleForm.minPrice) {
        const maxPrice = this.ruleForm.minPrice
        this.ruleForm.minPrice = this.ruleForm.maxPrice
        this.ruleForm.maxPrice = maxPrice
      }
      this.priceLabelIds = this.price.filter(item => item.state).map(item => {
        return item.id
      })
      if (!this.noLimitPrice) {
        this.$refs.menu.$el.children[0].children[1].className += ' drop-item-active'
        console.log('---', this.$refs.menu.$el.children[0].children[1])
      } else {
        this.$refs.menu.$el.children[0].children[1].classList.remove('drop-item-active');
      }
      this.$refs.item2.toggle();
      this.search()
    },
    // 限制输入框
    NumberCheck (num, val) {
      let str = val === 'totalArea' ? Number(Number(num).toFixed(0)) : Number(Number(num).toFixed(2));
      if (str < 0) str = str * -1;
      if (str.toString().length > 10) str = str.toString().substring(0, 10);
      if (val === 'minPrice' || val === 'minPrice') {
        this.price.forEach(item => {
          item.state = false
        })
        this.noLimitPrice = false
      }
      if (val === 'minArea' || val === 'minArea') {
        this.area.forEach(item => {
          item.state = false
        })
        this.noLimitArea = false
      }
      this.ruleForm[val] = str
    },
    // 排序
    sort () {
      this.$refs.item5.toggle();
      this.search()
    },
    nolimitArea () {
      this.area.forEach(item => {
        item.state = false
      })
      this.ruleForm.minArea = null
      this.ruleForm.maxArea = null
      this.noLimitArea = true
    },
    setArea (item) {
      item.state = !item.state
      this.ruleForm.minArea = null
      this.ruleForm.maxArea = null
      this.noLimitArea = false
    },
    getArea () {
      if (this.ruleForm.maxArea < this.ruleForm.minArea) {
        const maxArea = this.ruleForm.minArea
        this.ruleForm.minArea = this.ruleForm.maxArea
        this.ruleForm.maxArea = maxArea
      }
      this.areaLabelIds = this.area.filter(item => item.state).map(item => {
        return item.id
      })
      if (!this.noLimitArea) {
        this.$refs.menu.$el.children[0].children[2].className += ' drop-item-active'
        console.log('---', this.$refs.menu.$el.children[0].children[2])
      } else {
        this.$refs.menu.$el.children[0].children[2].classList.remove('drop-item-active');
      }
      this.$refs.item3.toggle();
      this.search()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList()
    },
    onLoad () {
      console.log('----', this.plantList);
      this.page += 1
      this.getList()
    },
    tabItem (val) {
      this.headTabIndex = val.id
      if (val.id === 2) {
        this.$router.push({
          path: `/searchGardenArea/kw/dr`,
        })
      }
      if (val.id === 3) {
        this.$router.push({
          name: 'mapSearch'
        })
      }
    },
    search () {
      this.page = 1;
      // 重置路由
      console.log('------------', this.$route, this.streetId);
      history.replaceState(null, null, `${location.pathname}#/plantRental_m/cs${this.$route.params.city.split('cs')[1] || ''}/kw${this.keyword || ''}/dr${this.districtId || ''}/al/s${this.streetId || ''}`)
      this.plantList = []
      this.getList()
    },
    dropDown () {
      this.drop = !this.drop
    },
    getList () {
      let cityId = JSON.parse(localStorage.city).cityId
      let params = {
        cityId,
        keywords: this.keyword,
        page: this.page,
        streetId: this.streetId,
        districtId: this.districtId,
        priceLabelIds: this.priceLabelIds,
        areaLabelIds: this.areaLabelIds,
        minArea: this.ruleForm.minArea,
        maxArea: this.ruleForm.maxArea,
        minPrice: this.ruleForm.minPrice,
        maxPrice: this.ruleForm.maxPrice,
        floorIds: this.floorIds,
        buildLabelIds: this.buildLabelIds,
        facilitiesIds: this.facilitiesIds,
        yearIds: this.yearIds,
        buildTypeIds: this.buildTypeIds,
        structureIds: this.structureIds,
        letMethod: this.letMethod,
        sourceType: this.sourceType,
        totalFloor: this.totalFloor,
        sortMethod: this.sortMethod,
      }
      console.log(params);
      this.loading = true
      searchPage(params).then(res => {
        if (res.data.status === 200) {
          this.plantList = this.plantList.concat(res.data.data)
          this.total = res.data.total
          if (this.page > Math.ceil(this.total / 10)) this.finished = true
        }
        this.loading = false
      })
    },
    setMore (item, str) {
      if (item)
        item.state = !item.state
      if (str === 'yearIds') {
        if (!item) {
          this.ageLimit.forEach(item => {
            item.state = false
          })
          this.yearIds = []
        } else {
          this.yearIds = this.handleArr(this.ageLimit)
        }
      }
      if (str === 'floorIds') {
        if (!item) {
          this.floor.forEach(item => {
            item.state = false
          })
          this.floorIds = []
        } else {
          this.floorIds = this.handleArr(this.floor)
        }
      }
      if (str === 'buildTypeIds') {
        if (!item) {
          this.type.forEach(item => {
            item.state = false
          })
          this.buildTypeIds = []
        } else {
          this.buildTypeIds = this.handleArr(this.type)
        }
      }
      if (str === 'structureIds') {
        if (!item) {
          this.structure.forEach(item => {
            item.state = false
          })
          this.structureIds = []
        } else {
          this.structureIds = this.handleArr(this.structure)
        }
      }
      if (str === 'facilitiesIds') {
        if (!item) {
          this.facility.forEach(item => {
            item.state = false
          })
          this.facilitiesIds = []
        } else {
          this.facilitiesIds = this.handleArr(this.facility)
        }
      }
      if (str === 'buildLabelIds') {
        if (!item) {
          this.feature.forEach(item => {
            item.state = false
          })
          this.buildLabelIds = []
        } else {
          this.buildLabelIds = this.handleArr(this.feature)
        }
      }
    },
    handleArr (arr) {
      return arr.filter(item => item.state).map(item => {
        return item.id
      })
    },
    resetMore () {
      this.setMore(null, 'yearIds')
      this.setMore(null, 'floorIds')
      this.setMore(null, 'buildTypeIds')
      this.setMore(null, 'structureIds')
      this.setMore(null, 'facilitiesIds')
      this.setMore(null, 'buildLabelIds')
      this.letMethod = ''
      this.sourceType = ''
      this.totalFloor = ''
    },
    confirmMore () {
      this.$refs.item4.toggle();
      this.search()
    },

    toPlantDetails (item) {
      this.$router.push({
        path: '/plantDetails_m' + "/" + item.id,
      })

      // let routeUrl = this.$router.resolve({
      //   path: '/plantDetails_m',
      //   data: {
      //     item: JSON.stringify(item)
      //   }
      // })
      // window.open(routeUrl.href + "/" + item.id, '_blank');
    },
  }
};
</script>
<style lang="less" scoped>
.plant-rental {
  min-width: 0 !important;
  header {
    height: 44px;
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
  }
  .search {
    padding: 8px 16px;
    /deep/ .van-field__value {
      height: 41.6px;
      opacity: 1;
      border-radius: 30px;
      background: rgba(255, 255, 255, 1);
      border: 0.6px solid rgba(236, 102, 0, 1);
      box-shadow: 0px 0px 4px rgba(236, 102, 0, 0.04);
      padding: 3px;
      .van-field__body {
        padding-left: 17px;
        .search-btn {
          width: 64px;
          height: 34px;
          opacity: 1;
          border-radius: 30px;
          background: rgba(236, 102, 0, 1);
          border: 0.5px solid rgba(236, 102, 0, 1);
          box-shadow: 0px 0px 4px rgba(236, 102, 0, 0.04);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 19.6px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .more-search {
    height: 36px;
    /deep/.van-dropdown-menu__bar {
      height: 36px;
      box-shadow: none !important;
    }
    /deep/ .van-dropdown-menu__title {
      color: #333;
      &::after {
        border-color: transparent transparent rgba(153, 153, 153, 1)
          rgba(153, 153, 153, 1);
        content: '';
      }
    }
    /deep/ .drop-item-active {
      .van-ellipsis {
        color: #ec6600 !important;
      }
      .van-dropdown-menu__title::after {
        border-color: transparent transparent #ec6600 #ec6600;
      }
    }
    &-content {
      width: 100%;
      height: 214px;
      overflow: auto;
      background-color: #fff;
      .tree-select {
        height: 214px !important;
        /deep/ .van-tree-select__nav-item {
          text-align: center;
        }
        /deep/ .van-sidebar-item--select,
        .van-sidebar-item--select:active {
          color: rgba(236, 102, 0, 1);
          &::before {
            width: 0px !important;
            content: '';
          }
        }
        /deep/ .van-tree-select__item {
          padding-left: 50px;
          padding-right: 40px;
        }
        /deep/ .van-tree-select__item--active {
          color: rgba(236, 102, 0, 1);
        }
      }
      .content {
        padding: 12px 20px 0;
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: calc(50% - 6px);
          height: 28px;
          opacity: 1;
          border-radius: 4px;
          background: rgba(245, 245, 245, 1);
          margin-bottom: 12px;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 28px;
          text-align: center;
          color: rgba(51, 51, 51, 1);
          &:nth-child(2n-1) {
            margin-right: 12px;
          }
          &.active {
            background: rgba(255, 243, 235, 1);
            color: rgba(236, 102, 0, 1);
          }
        }
      }
      p.tit {
        padding-left: 22px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 19.6px;
        color: rgba(0, 0, 0, 1);
      }
      .custom {
        padding-left: 22px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        .min,
        .max {
          width: 104px;
          height: 28px;
        }
        /deep/ .el-input__inner {
          width: 104px;
          height: 28px;
          border: 0;
          opacity: 1;
          border-radius: 4px;
          background: rgba(245, 245, 245, 1);
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          &::placeholder {
            font-size: 12px;
            font-weight: 500;
            color: rgba(153, 153, 153, 1);
          }
        }
        .line {
          margin: 0 2px;
          color: rgba(153, 153, 153, 1);
        }
      }
      .list-item {
        padding: 14px 0;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        line-height: 18.2px;
        color: rgba(51, 51, 51, 1);
        &.active {
          color: rgba(236, 102, 0, 1);
        }
      }
    }
    &-btn {
      display: flex;
      align-items: center;
      box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.04);
      .reset {
        width: calc(50% - 3px);
        height: 36px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(244, 244, 244, 1);
        margin-right: 6px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        border: 0;
      }
      .determine {
        width: calc(50% - 3px);
        height: 36px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(236, 102, 0, 1);
        font-size: 14px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        border: 0;
      }
    }
  }
  .list-box {
    height: calc(100% - 140px);
    background-color: rgba(249, 248, 247, 1);
    padding: 7px 8px;
    overflow: auto;
    &-item {
      height: 127px;
      opacity: 1;
      border-radius: 6px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 4px rgba(236, 102, 0, 0.04);
      margin-bottom: 8px;
      padding: 11px;
      display: flex;
      .img {
        width: 140px;
        height: 105px;
        margin-right: 8px;
        border-radius: 4px;
        border: 1px solid rgba(238, 238, 238, 1);
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .list-content {
        width: calc(100% - 150px);
        .title {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 18px;
          color: rgba(51, 51, 51, 1);
          margin-bottom: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; // 控制多行的行数
          -webkit-box-orient: vertical;
        }
        .info {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          &-item {
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 14px;
            color: rgba(153, 153, 153, 1);
            &:nth-child(1) {
              color: rgba(51, 51, 51, 1);
            }
          }
          .line {
            margin: 0 4px;
            width: 1px;
            height: 10px;
            background-color: rgba(238, 238, 238, 1);
          }
        }
        .label {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 6px;
          &-item {
            padding: 0 3px;
            height: 20px;
            opacity: 1;
            border-radius: 2px;
            background: rgba(255, 233, 199, 1);
            // border: 0.6px solid rgba(255, 233, 199, 1);
            font-size: 10px;
            font-weight: 500;
            // line-height: 20px;
            display: flex;
            align-items: center;
            color: rgba(247, 152, 0, 1);
            text-align: center;
            margin-right: 4px;
          }
        }
        .count {
          font-size: 17px;
          font-weight: 700;
          line-height: 23.8px;
          color: rgba(236, 102, 0, 1);
        }
      }
    }
  }
}
</style>
  