<template>
  <div class="look-house-subscribe">
    <div class="title">共<span>20</span>个 待看房预约</div>
    <div class="tab">
      <div
        class="tab-item"
        v-for="item in tabList"
        :key="item.id"
        :class="tab === item.id ? 'active' : ''"
        @click="tab = item.id"
      >
        {{ item.text }}
      </div>
    </div>
    <main class="main">
      <div class="main-list">
        <div class="main-list-item" v-for="item in plantList" :key="item.id">
          <div class="main-list-item-left">
            <img :src="item.imgUrl" alt="" />
            <div class="left-content">
              <p class="name">{{ item.name }}</p>
              <p class="gs">{{ item.gs }}</p>
              <div class="rate">
                <i
                  v-for="i in 5"
                  class="icon icon-rate"
                  :key="i"
                  :class="item.rateCount < i ? 'icon-rate-gray' : ''"
                ></i>
              </div>
            </div>
          </div>
          <div class="main-list-item-right">
            <div class="time">
              <i class="el-icon-time"></i>
              <span>{{ item.time }}</span>
            </div>
            <div class="phone">
              <i class="icon icon-user-phone"></i>
              <span>电话联系</span>
            </div>
          </div>
        </div>
      </div>
      <div class="main-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="100"
          layout="total, prev, pager, next, jumper"
          :total="1000"
        >
        </el-pagination>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: 'LookHouseSubscribe',
  components: {},
  data () {
    return {
      tabList: [
        { id: 1, text: '我的看房预约' },
        { id: 2, text: '过期的预约' }
      ],
      value1: 4,
      tab: '',
      navIndex: 1,
      currentPage3: 1,
      plantList: [
        {
          id: 1,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          name: '李晓明',
          gs: '深圳市利达地产',
          rateCount: 5,
          time: '2023-09-25 10:21:43'
        },
        {
          id: 2,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          name: '李晓明',
          gs: '深圳市利达地产',
          rateCount: 4,
          time: '2023-09-25 10:21:43'
        },
        {
          id: 3,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          name: '李晓明',
          gs: '深圳市利达地产',
          rateCount: 3,
          time: '2023-09-25 10:21:43'
        },
        {
          id: 4,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          name: '李晓明',
          gs: '深圳市利达地产',
          rateCount: 2,
          time: '2023-09-25 10:21:43'
        },
        {
          id: 5,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          name: '李晓明',
          gs: '深圳市利达地产',
          rateCount: 5,
          time: '2023-09-25 10:21:43'
        },
        {
          id: 6,
          imgUrl: 'https://img.js.design/assets/img/650ad1ba5fa825aa2b75200f.png#ad5093d5f54d67f2ebf992ea995d7e84',
          name: '李晓明',
          gs: '深圳市利达地产',
          rateCount: 5,
          time: '2023-09-25 10:21:43'
        },
      ]
    }
  },
  mounted () { },
  methods: {
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
    toPush (item) {
      this.navIndex = item.id
    }
  }
};
</script>

<style lang="less" scoped>
.look-house-subscribe {
  height: 100%;
  padding: 40px 36px 24px 24px;
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(51, 51, 51, 1);
    span {
      font-size: 22px;
      font-weight: 600;
      line-height: 22px;
      color: rgba(236, 102, 0, 1);
    }
  }
  .tab {
    height: 40px;
    background: rgba(246, 245, 244, 1);
    border: 1px solid rgba(246, 245, 244, 1);
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 16px;
    .tab-item {
      width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      cursor: pointer;
      &.active {
        background: rgba(255, 255, 255, 1);
        color: rgba(236, 102, 0, 1);
        border-top: 1px solid rgba(236, 102, 0, 1);
      }
    }
  }
  .main {
    height: calc(100% - 100px);
    &-list {
      overflow: auto;
      height: calc(100% - 60px);
      &-item {
        height: 112px;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {
          display: flex;
          align-items: center;
          img {
            width: 80px;
            height: 80px;
            margin-right: 26px;
            border-radius: 50%;
          }
          .left-content {
            .name {
              font-size: 20px;
              font-weight: 600;
              line-height: 30px;
              color: rgba(0, 0, 0, 1);
              margin-bottom: 4px;
            }
            .gs {
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: rgba(136, 136, 136, 1);
              margin-bottom: 8px;
            }
          }
        }
        &-right {
          text-align: end;
          display: flex;
          flex-direction: column;
          align-items: end;
          .time {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: rgba(51, 51, 51, 1);
            margin-bottom: 15px;
            i {
              font-size: 14px;
              color: rgba(236, 102, 0, 1);
              margin-right: 8px;
            }
          }

          .phone {
            width: 110px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: rgba(232, 242, 252, 1);
            font-size: 14px;
            font-weight: 400;
            color: rgba(16, 118, 235, 1);
            cursor: pointer;
            span {
              margin-left: 8px;
            }
          }
        }
      }
    }
    &-page {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: rgba(236, 102, 0, 1);
      }
    }
  }
}

.rate {
  display: flex;
  justify-content: center;
  i {
    margin-right: 4px;
    width: 12px;
    height: 12px;
  }
}
</style>