<template>
  <div class="details-carousel">
    <div class="big-img-box" v-if="imgVisible">
      <i class="el-icon-close" @click="close"></i>
      <div class="main">
        <div class="img-big">
          <el-carousel
            class="img-big-box"
            ref="img"
            :initial-index="index"
            trigger="click"
            height="750px"
            :autoplay="false"
            @change="setImg"
          >
            <el-carousel-item v-for="(url, index) in imgList" :key="index">
              <img :src="los + url.url + '?' + Date.now()" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="carousel">
          <i class="el-icon-arrow-left" @click="left"></i>
          <main>
            <div
              class="img-box"
              :style="{ transform: `translateX(${length}px)` }"
            >
              <div class="img" v-for="(url, index) in imgList" :key="index">
                <el-image
                  @click="selImg(url)"
                  :class="imgIndex === url.id ? 'imgActive' : ''"
                  :src="los + url.url + '?' + Date.now()"
                  class="carousel-img"
                  lazy
                ></el-image>
              </div>
            </div>
          </main>
          <i class="el-icon-arrow-right" @click="right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const los = window.los
export default {
  name: 'DetailsCarousel',
  components: {
  },
  props: {
    details: Object,
    entrance: String
  },
  data () {
    return {
      los,
      index: 0,
      url: '',
      imgIndex: 1,
      type: 1,
      img: [],
      length: null,
      count: 1,
      imgList: [],
      modelExist: true,
      vrExist: true,
      videoExist: true,
      imgVisible: false,
    }
  },
  mounted () {
    let arr = []
    // 判断是否存在模型、全景、视频
    if (this.entrance === 'big-img') {
      arr.push({ url: this.details.coverImg, type: 1 })
      // if (this.modelExist) arr.push({ url: this.details.coverImg, type: 1 })
      // if (this.vrExist) arr.push({ url: this.details.coverImg, type: 2 })
      // if (this.videoExist) arr.push({ url: this.details.coverImg, type: 3 })
    }
    if (this.details.images)
      arr = arr.concat(this.details.images || [])
    this.img = arr.map((item, index) => {
      return {
        url: item.url || item,
        id: index + 1,
        type: item.type || 4
      }
    })
    this.url = this.details.coverImg
    this.imgList = this.img
  },
  methods: {
    close () {
      this.imgVisible = false
    },
    toType () {
      console.log('aaaa')
      if (this.type === 1) this.toModel()
      if (this.type === 2) this.toVR()
    },
    selType (type) {
      this.type = type
    },
    toVR () {
      this.type = 2;
      let routeUrl = this.$router.resolve({
        path: '/vr',
      })
      window.open(routeUrl.href, '_blank');
    },
    toModel () {
      this.type = 1;
      let routeUrl = this.$router.resolve({
        path: '/model',
      })
      window.open(routeUrl.href, '_blank');
    },
    selBigImg (item) {
      this.imgVisible = true
      this.selImg(item)
    },
    selImg (item) {
      this.type = item.type
      this.imgIndex = item.id;
      console.log(item.type, this.count);
      if (this.count > item.id) {
        const num = this.count - item.id
        for (let index = 0; index < num; index++) {
          this.$refs.img.prev()
        }
      } else if (this.count < item.id) {
        const num = item.id - this.count
        for (let index = 0; index < num; index++) {
          console.log(this.$refs);
          this.$refs.img.next()
        }
      }
      this.count = item.id
    },
    left () {
      if (this.img.length > 0) {
        this.count -= 1;
        this.$refs.img.prev()
        this.imgIndex = this.count
        if (!this.count) {
          this.count = this.img.length;
          this.imgIndex = this.count
          this.length = this.img.length > 6 ? -(this.count - 6) * 104 : this.length
        }
        if (this.img.length > 6) {
          let a = this.img.length - 5
          if (this.count < a) {
            this.length = (-this.count + 1) * 104
          }
        }
      }
    },
    right () {
      if (this.img.length > 0) {
        this.count += 1;
        this.$refs.img.next()
        this.imgIndex = this.count
        if (this.img.length > 6) {
          if (this.count > 6) {
            this.length = (-this.count + 6) * 104
          }
        }
        if (this.count > this.img.length) {
          this.count = 1;
          this.imgIndex = this.count
          this.length = this.img.length > 6 ? 0 : this.length
        }
      }
    },
    setImg (val) {
      console.log(val);
      this.type = this.imgList[val].type
      this.count = val + 1
      this.imgIndex = this.count
      if (!this.count) {
        this.count = this.img.length;
        this.imgIndex = this.count
        this.length = this.img.length > 6 ? -(this.count - 6) * 104 : this.length
      }
      if (this.img.length > 6) {
        let a = this.img.length - 5
        if (this.count < a) {
          this.length = (-this.count + 1) * 104
        }
      }
      if (this.img.length > 6) {
        if (this.count > 6) {
          this.length = (-this.count + 6) * 104
        }
      }
      if (this.count > this.img.length) {
        this.count = 1;
        this.imgIndex = this.count
        this.length = this.img.length > 6 ? 0 : this.length
      }
    },
  }
};
</script>
<style lang="less" scoped>
.details-carousel {
  .img-big {
    width: 100%;
    height: 533px;
    margin-bottom: 12px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    &-box {
      position: relative;
      .look-house-type {
        position: absolute;
        z-index: 100;
        background: rgba(0, 0, 0, 0.36);
        overflow: hidden;
        border-radius: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        .type-item {
          padding: 0 10px;
          font-size: 14px;
          height: 26px;
          color: rgba(255, 255, 255, 1);
          line-height: 26px;
          &.active {
            border-radius: 20px;
            background: rgba(51, 48, 46, 1);
          }
        }
        .line {
          width: 1px;
          height: 100%;
          background: #eee;
        }
      }
      .gif {
        position: absolute;
        z-index: 100;
        width: 90px;
        height: 90px;
        opacity: 1;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
    /deep/ .special-offer {
      width: 136px;
      height: 50px;
      background: rgba(247, 162, 25, 1);
      border: 1px solid rgba(247, 162, 25, 1);
      box-shadow: 0px 2px 2px rgba(38, 24, 0, 0.16);
      font-size: 24px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      line-height: 50px;
      left: 0;
      padding: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .carousel {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    .el-icon-arrow-left {
      width: 38px;
      height: 70px;
      color: #fff;
      font-size: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      background: #33302e;
    }
    main {
      width: calc(100% - 76px);
      margin: 0 10px;
      height: 70px;
      overflow: hidden;
      .img-box {
        display: flex;
        align-items: center;
        transition: all 0.3s ease;
        height: 70px;
        .img {
          height: 70px;
          &:first-child {
            img {
              margin-left: 0;
            }
          }
        }
      }
    }
    .el-icon-arrow-right {
      width: 38px;
      height: 70px;
      color: #fff;
      font-size: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      background: #33302e;
    }
  }
  .carousel-img {
    width: 94px;
    height: 70px;
    border-radius: 4px;
    margin-left: 10px;
    opacity: 0.5;
    cursor: pointer;
    &.imgActive {
      opacity: 1;
    }
    &:hover {
      opacity: 1;
    }
  }
  .big-img-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background-color: rgba(0, 0, 0, 0.8);
    .el-icon-close {
      position: absolute;
      top: 100px;
      right: 100px;
      cursor: pointer;
      font-size: 50px;
      color: #fff;
    }
    .main {
      width: 1000px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .img-big {
        height: 750px;
      }
      .carousel {
        main {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  /deep/ .el-carousel__indicators--horizontal {
    display: none;
  }
  /deep/ .el-carousel__arrow {
    width: 38px;
    height: 70px;
    border-radius: 0;
    background: #33302e;
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
  /deep/ .el-carousel__arrow i {
    font-size: 40px;
  }
}
</style>