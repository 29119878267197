<template>
  <div class="special-offer">{{ label }}</div>
</template>
    <script>
export default {
  name: 'SpecialOffer',
  props: {
    label: String,
  },
  data () {
    return {
    }
  },
  methods: {
    toPush (item) {
      this.$router.push({
        path: item.url
      })
    }
  }
};
    </script>
    
    <style lang="less" scoped>
.special-offer {
  padding: 5px 10px;
  background: rgba(247, 162, 25, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}
</style>
    