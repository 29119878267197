<template>
  <div class="sidebar">
    <div class="sidebar-box">
      <div class="sidebar-box-item" @click="toPublishHousing">
        <img src="../../assets/home/publish.png" alt="发布房源" />
        <div class="text">发布房源</div>
      </div>
      <div class="sidebar-box-item code">
        <img src="../../assets/home/QRcode.png" alt="手机看房" />
        <div class="text">手机看房</div>
        <img class="QRcode" src="../../assets/xcx-code1.png" alt="" />
      </div>
      <div class="sidebar-box-item" @click="toCooperate">
        <img src="../../assets/home/cooperation.png" alt="合作" />
        <div class="text">合作</div>
      </div>
      <div class="sidebar-box-item" @click="toFeedBack">
        <img src="../../assets/home/frrdback.png" alt="意见反馈" />
        <div class="text">意见反馈</div>
      </div>
      <div class="sidebar-box-item" @click="toAbout">
        <img src="../../assets/home/about.png" alt="关于我们" />
        <div class="text">关于我们</div>
      </div>
    </div>

    <!-- 意见反馈 -->
    <el-dialog
      :modal="true"
      title="意见反馈"
      :visible.sync="feedbackVisible"
      width="560px"
      :before-close="feedbackHandleClose"
    >
      <div class="body">
        <div class="li">一. 问题类型</div>
        <div class="main">
          <el-radio-group v-model="type">
            <el-radio :label="1">我想提建议</el-radio>
            <el-radio :label="3">功能使用有疑问</el-radio>
            <el-radio :label="2">系统问题</el-radio>
            <el-radio :label="4">其他类别</el-radio>
          </el-radio-group>
        </div>
        <div class="li">二. 具体描述</div>
        <div class="main">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入您的意见和反馈"
            v-model="content"
          >
          </el-input>
        </div>
        <div class="li">三. 手机号码</div>
        <div class="main">
          <el-input
            v-model="contact"
            placeholder="请输入手机号码（选填）或者邮箱"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="feedbackHandleClose">取 消</el-button>
        <el-button class="btn" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { submitFeedBack } from 'services/member'
export default {
  name: 'Sidebar',
  data () {
    return {
      feedbackVisible: false,
      type: 1,
      content: '',
      contact: ''
    }
  },
  mounted () { },
  methods: {
    feedbackHandleClose () {
      this.content = ''
      this.contact = ''
      this.feedbackVisible = false
    },
    toAbout () {
      let routeUrl = this.$router.resolve({
        path: '/my',
      })
      window.open(routeUrl.href, '_blank');
    },
    toFeedBack () {
      this.feedbackVisible = true
    },
    toPublishHousing () {
      window.open(`${window.guwen}/#/login?client=plant`, '_blank');
    },
    toCooperate () {
      let routeUrl = this.$router.resolve({
        path: '/cooperate',
      })
      window.open(routeUrl.href, '_blank');
    },
    submit () {
      if (!this.type) {
        return this.$message.warning('请选择类型')
      } else if (!this.content) {
        return this.$message.warning('请输入详细内容')
      } else {
        const city = localStorage.city
        let params = {
          cityId: JSON.parse(city).cityId,
          type: this.type,
          content: this.content,
          contact: this.contact
        }
        submitFeedBack(params).then(res => {
          if (res.data.status === 200) {
            this.feedbackVisible = false
            this.content = ''
            this.contact = ''
            return this.$message({
              type: 'success',
              message: res.data.msg
            })
          }
        })
      }

    }
  }
};
</script>
<style lang="less" scoped>
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 60px;
  height: 100%;
  background-color: #fff;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  &-box {
    width: 100%;
    &-item {
      width: 100%;
      text-align: center;
      padding: 13px 16px;
      margin-bottom: 9px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #eee;
      }
      img {
        width: 28px;
        height: 28px;
        margin-bottom: 4px;
      }
      .text {
        width: 28px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(51, 51, 51, 1);
      }
      &.code:hover {
        .QRcode {
          display: block;
        }
      }
      .QRcode {
        position: absolute;
        top: 0;
        right: 40px;
        width: 100px;
        height: 100px;
        box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.06);
        display: none;
      }
    }
  }
}

// 弹窗
/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 42px;
}
.body {
  .li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .main {
    margin: 8px 0;
    padding-left: 26px;
    /deep/ .el-radio__label {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(51, 51, 51, 1);
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner {
      border-color: rgba(236, 102, 0, 1);
      background: #fff;
    }
    /deep/ .el-radio__input.is-checked .el-radio__inner::after {
      background-color: rgba(236, 102, 0, 1);
    }
    /deep/ .el-textarea__inner {
      height: 98px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);
      border: 0;
    }
    /deep/ .el-input__inner {
      height: 42px;
      line-height: 42px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(247, 247, 247, 1);
      border: 0;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
</style> 