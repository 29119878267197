<template>
  <div class="map-search-header">
    <div class="logo-tab">
      <img class="logo" src="../../assets/plant/details/logo.png" alt="亿楼" />
      <Nav />
    </div>
    <div class="login">
      <!-- <div class="sel">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="name">
        <div class="user">
          <i
            v-if="!userInfo.phone || !userInfo.headImg"
            class="icon icon-user"
          ></i>
          <img
            v-else
            :src="los + userInfo.headImg + '?' + Date.now()"
            alt=""
            class="tx-img"
            @click="toPushUser(info[0])"
          />
          <span
            v-if="userInfo.phone"
            @click="toPushUser(info[0])"
            style="cursor: pointer"
            >{{ userInfo.phone | phoneFilter }}</span
          >
        </div>
        <div class="login-btn" v-if="userInfo.phone" @click="loginORquit">
          退出
        </div>
        <div class="login-btn" v-else @click="loginORquit">登录</div>
        <!-- <div class="userinfo">
          <div
            class="info-item"
            v-for="item in info"
            :key="item.id"
            @click="toPush(item)"
          >
            {{ item.text }}
          </div>
        </div> -->
      </div>
    </div>
    <!-- 登录弹窗 -->

    <el-dialog :visible.sync="dialogVisible" width="400px">
      <div class="body">是否确认退出登录</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toLogout">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>
import Nav from './Nav.vue';
import { logout } from 'services/login';
const los = window.los
export default {
  name: 'MapSearchHeader',
  props: {
    plantList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Nav,
  },
  data () {
    return {
      los,
      dialogVisible: false,
      info: [
        { id: 1, text: '个人中心', url: '/userHome', },
        { id: 2, text: '关注房源', url: '', },
        { id: 3, text: '看房记录', url: '', },
      ],
      userInfo: {},
      options: [{
        value: '1',
        label: '深圳'
      }, {
        value: '2',
        label: '广州'
      }],
      value: '1'
    }
  },
  computed: {
  },
  mounted () {
    // 获取用户信息
    if (localStorage.userInfo) this.userInfo = JSON.parse(localStorage.userInfo)
    this.$bus.$on('getUserInfo', userInfo => {

      this.userInfo = userInfo
    })
  },
  methods: {
    // 退出-登录
    loginORquit () {
      if (this.userInfo.phone) {
        this.dialogVisible = true
      } else {
        setTimeout(() => {
          window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
        }, 500)
      }
    },
    toLogout () {
      logout().then(res => {
        if (res.data.status === 200) {
          this.userInfo = {}
          localStorage.removeItem('userInfo')
          localStorage.removeItem('token')
          this.dialogVisible = false
          return this.$message({ message: res.data.msg, type: 'success' });
        } else {
          return this.$message.error('退出登录失败，请再次点击')
        }
      })
    },
    toPush (item) {
      this.$router.push({
        path: item.url
      })
    },
    toPushUser (item) {
      let routeUrl = this.$router.resolve({
        path: item.url
      })
      window.open(routeUrl.href, '_blank');
    },
  }
};
  </script>
  <style lang="less" scoped>
.map-search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background: rgba(255, 255, 255, 1);
  padding: 0 calc(50% - 600px);
  .logo-tab {
    display: flex;
    align-items: center;
    .logo {
      width: 54px;
      // height: 20px;
      margin-right: 50px;
    }
  }

  .login {
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    .sel {
      /deep/ .el-input__inner {
        width: 60px;
        padding: 0;
        border: 0;
      }
    }
    .user {
      display: flex;
      align-items: center;
      margin-right: 8px;
      font-size: 14px;
      font-weight: 600;
      line-height: 12px;
      .tx-img {
        width: 24px;
        height: 24px;
        border: 1px solid #eee;
      }
      span {
        color: rgba(51, 51, 51, 1) !important;
        font-weight: normal;
      }
    }
    .name {
      display: flex;
      align-items: center;
      height: 40px;
      cursor: pointer;
    }
    .name:hover {
      .userinfo {
        display: block;
      }
    }
    .icon {
      margin-right: 8px;
    }
    &-btn {
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      color: rgba(236, 102, 0, 1);
      cursor: pointer;
    }
    .userinfo {
      position: absolute;
      z-index: 100;
      top: 30px;
      background-color: #fff;
      box-shadow: 1px 1px 1px 0 #f5f5f5;
      display: none;
      border-radius: 4px;
      .info-item {
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    display: flex;
    align-items: center;
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
</style>
    