<template>
  <div class="user-home">
    <div class="banner">
      <div class="banner-header">
        <div class="banner-header-left">
          <img src="../../assets/home/logo.png" alt="亿楼" />
          <!-- <Location class="location" /> -->
          <div class="nav">
            <div
              class="nav-item"
              v-for="item in navList"
              :key="item.id"
              @click="toPushNav(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="banner-header-right">
          <div class="login">
            <div class="user">
              <i
                style="width: 24px; height: 24px"
                v-if="!user.phone || !user.headImg"
                class="icon icon-user"
              ></i>
              <img
                v-else
                :src="los + user.headImg + '?' + Date.now()"
                alt=""
                class="tx-img"
                @click="toPush(info[0])"
              />
              <span
                v-if="user.phone"
                @click="toPush(info[0])"
                style="cursor: pointer; color: #fff"
                >{{ user.phone | phoneFilter }}</span
              >
            </div>
            <div class="login-btn" @click="loginORquit">
              {{ user.phone ? '退出' : '登录' }}
            </div>
            <!-- <div class="userinfo">
              <div
                class="info-item"
                v-for="item in info"
                :key="item.id"
                @click="toPush(item)"
              >
                {{ item.text }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <main class="main">
      <div class="main-left">
        <img
          class="img"
          v-if="!user.headImg"
          src="../../assets/user/tx.png"
          alt=""
        />
        <img
          class="img"
          v-else
          :src="los + user.headImg + '?' + Date.now()"
          alt=""
        />
        <p class="user-name">{{ user.phone | phoneFilter }}</p>
        <div class="nav-box">
          <div class="nav">
            <div
              class="nav-item"
              v-for="item in nav"
              :key="item.id"
              :class="navIndex === item.id ? 'active' : ''"
              @click="toPush(item)"
            >
              <i
                class="icon"
                :class="
                  navIndex !== item.id ? item.icon : `${item.icon}-active`
                "
              ></i>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="main-right">
        <router-view />
      </div>
    </main>
    <!-- 登录弹窗 -->

    <el-dialog :visible.sync="dialogVisible" width="400px">
      <div class="body">是否确认退出登录</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toLogout">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { userInfo, logout } from 'services/login';
const los = window.los
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      los,
      dialogVisible: false,
      user: {
      },
      info: [
        { id: 1, text: '个人中心', url: '/userHome', },
        { id: 2, text: '关注房源', url: '', },
        { id: 3, text: '看房记录', url: '', },
      ],
      navList: [
        { id: 1, url: '', name: '写字楼' },
        { id: 2, url: '/plant', name: '厂房' },
        // { id: 3, url: '', name: '仓库' },
        // { id: 4, url: '', name: '商铺' },
        // { id: 5, url: '', name: '土地' },
        // { id: 6, url: '/my', name: '我的' },
      ],
      nav: [
        { id: 1, icon: 'icon-user-collect', url: '/collectionHousing', name: '收藏的房源' },
        // { id: 2, icon: 'icon-user-look-house', url: '/lookHouseSubscribe', name: '看房预约' },
        // { id: 3, icon: 'icon-user-my-rating', url: '/myRate', name: '我的评价' },
        { id: 4, icon: 'icon-user-user-info', url: '/userInfo', name: '个人信息' },
        // { id: 5, icon: 'icon-user-my-earnings', url: '/myEarnings', name: '我的收益' }
      ],
      navIndex: 1
    }
  },
  computed: {
  },
  mounted () {
    // 判断token是否失效
    this.$bus.$on('isLoginType', status => {
      if (!status) {
        if (!sessionStorage.ticket)
          var timer = setTimeout(() => {
            window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
            clearTimeout(timer)
          }, 500)
      }
    })
    this.getUserInfo()
    console.log(window.location.hash.split('#')[1]);
    const url = window.location.hash.split('#')[1]
    this.nav.forEach(item => {
      if (item.url === url) this.navIndex = item.id
    })
  },
  methods: {
    // 获取用户信息
    getUserInfo () {
      userInfo().then(res => {
        if (res.data.status === 200) {
          this.user = res.data.data
        }
      })
    },
    // 退出-登录
    loginORquit () {
      if (this.user.phone) {
        this.dialogVisible = true
      } else {
        setTimeout(() => {
          window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
        }, 500)
      }
    },
    toLogout () {
      logout().then(res => {
        if (res.data.status === 200) {
          this.user = {}
          localStorage.removeItem('userInfo')
          localStorage.removeItem('token')
          this.$router.push('/plant')
          return this.$message({ message: res.data.msg, type: 'success' });
        } else {
          return this.$message.error('退出登录失败，请再次点击')
        }
      })
    },
    toPushNav (item) {
      if (item.id === 2) {
        this.$router.push({
          path: item.url
        })
      }

      if (item.id === 1) {
        window.open(`${window.officeUrl}office`, '_blank');
      }
    },
    toPush (item) {
      this.navIndex = item.id
      this.$router.push({
        path: item.url
      })
    }
  }
};
</script>

<style lang="less" scoped>
.user-home {
  position: relative;
  .main {
    position: absolute;
    left: calc(50% - 600px);
    right: calc(50% - 600px);
    top: 250px;
    bottom: 30px;
    opacity: 1;
    border-radius: 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 16px rgba(236, 102, 0, 0.12);
    display: flex;
    align-items: center;
    &-left {
      width: 260px;
      height: 100%;
      border-radius: 16px 0px 0px 16px;
      background: rgba(250, 249, 247, 1);
      padding: 90px 40px;
      box-sizing: border-box;
      text-align: center;
      .img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-bottom: 16px;
      }
      .user-name {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 42px;
      }
      .nav-box {
        height: calc(100% - 200px);
        overflow: auto;
      }
      .nav {
        width: 100%;
        &-item {
          height: 44px;
          margin-bottom: 10px;
          padding-left: 30px;
          display: flex;
          align-items: center;
          // justify-content: center;
          border-radius: 80px;
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          color: rgba(51, 51, 51, 1);
          cursor: pointer;
          span {
            margin-left: 14px;
          }
          &.active {
            font-size: 16px;
            // font-weight: 600;
            line-height: 16px;
            color: rgba(255, 255, 255, 1);
            background: linear-gradient(
              90deg,
              rgba(236, 102, 0, 1) 0%,
              rgba(255, 176, 49, 1) 100%
            );
          }
        }
      }
    }
    &-right {
      width: calc(100% - 260px);
      height: 100%;
    }
  }
}
.banner {
  width: 100%;
  height: 400px;
  background: url(../../assets/user/banner.png);
  background-size: 100% 100%;
  padding: 0 calc(50% - 600px);
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    margin-bottom: 100px;
    &-left {
      display: flex;
      align-items: center;
      img {
        width: 114px;
        height: 34px;
        margin-right: 17px;
      }
      .icon {
        margin-right: 5px;
      }
      .nav {
        display: flex;
        align-items: center;
        &-item {
          margin-left: 28px;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 18px;
          color: rgba(255, 255, 255, 1);
          cursor: pointer;
        }
      }
    }
    &-right {
      .login {
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;
        .user {
          margin-right: 8px;
          display: flex;
          align-items: center;
          .tx-img {
            width: 24px;
            height: 24px;
          }
        }
        .icon {
          margin-right: 8px;
          width: 24px;
          height: 24px;
        }
        &-btn {
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          color: rgba(255, 255, 255, 1);
          cursor: pointer;
        }
        &:hover {
          .userinfo {
            display: block;
          }
        }
        .userinfo {
          position: absolute;
          top: 20px;
          background-color: #fff;
          display: none;
          border-radius: 4px;
          .info-item {
            width: 80px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            cursor: pointer;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.location {
  /deep/ .loaction-city {
    background: rgba(255, 255, 255, 0.24) !important;
  }
}

/deep/ .el-dialog__header {
  text-align: center;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  .el-dialog__headerbtn {
    font-size: 20px;
  }
}
/deep/ .el-dialog__body {
  padding: 20px 40px;
  .body {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  .btn {
    display: flex;
    align-items: center;
    background: rgba(236, 102, 0, 1);
    border: 0;
  }
  /deep/ .el-button {
    height: 36px;
  }
}
</style>