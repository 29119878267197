import { render, staticRenderFns } from "./tabModel.vue?vue&type=template&id=3071c943&scoped=true&"
import script from "./tabModel.vue?vue&type=script&lang=js&"
export * from "./tabModel.vue?vue&type=script&lang=js&"
import style0 from "./tabModel.vue?vue&type=style&index=0&id=3071c943&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3071c943",
  null
  
)

export default component.exports